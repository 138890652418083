import { defineComponent } from 'vue';
import index, { mapGetters } from 'vuex';
export default defineComponent({
    name: 'static-nav',
    props: {
        mobile_nav_headline: {
            required: false,
            type: String,
        },
        nav_elements: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            activeLinkIndex: 0,
        };
    },
    computed: Object.assign({ index() {
            return index;
        },
        headerHeight() {
            var _a, _b;
            if (this.isMobile) {
                return document.getElementsByTagName('header')[0].getBoundingClientRect().height + 'px';
            }
            return ((_b = (_a = document.getElementsByClassName('navBarPrimaryDesktop')[0]) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height) !== null && _b !== void 0 ? _b : 0 + 'px');
        } }, mapGetters('global', { isMobile: 'isMobile' })),
    methods: {
        checkIfAnker(targetType) {
            return targetType.startsWith('#');
        },
        scrollToTheAnker(target, index) {
            var _a;
            this.activeLinkIndex = index;
            if (window.innerWidth < 768) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                // eslint-disable-next-line no-undef
                window.scrollToAnker(target, 280);
            }
            else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                // eslint-disable-next-line no-undef
                window.scrollToAnker(target, -1);
            }
            if (this.isMobile) {
                const button = (_a = this.$refs) === null || _a === void 0 ? void 0 : _a.navMobileToggleButton;
                if (!(button instanceof HTMLButtonElement)) {
                    return;
                }
                button.click();
            }
        },
    },
});

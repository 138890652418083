export default class BaseApiClient {
    constructor(httpClient) {
        this.http_client = httpClient;
    }
    get(url, config) {
        return this.http_client.get(url, this.prepareParams(config));
    }
    delete(url, config) {
        return this.http_client.delete(url, this.prepareParams(config));
    }
    head(url, config) {
        return this.http_client.head(url, this.prepareParams(config));
    }
    options(url, config) {
        return this.http_client.options(url, this.prepareParams(config));
    }
    post(url, data, config) {
        return this.http_client.post(url, this.prepareData(data), this.prepareParams(config));
    }
    put(url, data, config) {
        return this.http_client.put(url, this.prepareData(data), this.prepareParams(config));
    }
    patch(url, data, config) {
        return this.http_client.patch(url, this.prepareData(data), this.prepareParams(config));
    }
    prepareParams(config) {
        if (typeof config === 'undefined' || typeof config.params === 'undefined') {
            return config;
        }
        config.params = this.translateDataTypes(config.params);
        return config;
    }
    prepareData(data) {
        return this.translateDataTypes(data);
    }
    translateDataTypes(data) {
        if (data === null || data === undefined) {
            return data;
        }
        if (data instanceof Array) {
            data.forEach((value, key, list) => (list[key] = this.translateDataTypes(value)));
            return data;
        }
        switch (typeof data) {
            case 'object':
                for (const [key, value] of Object.entries(data)) {
                    data[key] = this.translateDataTypes(value);
                }
                return data;
            case 'boolean':
                return data ? 1 : 0;
            default:
                return data;
        }
    }
}

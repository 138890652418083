export const blueGetters = {
    isCartFilled: (state) => { var _a, _b; return (state.cart === null ? null : ((_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.products.length) !== null && _b !== void 0 ? _b : 0) > 0); },
    currentShippingOption: (state) => { var _a; return (_a = state.shipping_options.find((shipping_option) => { var _a; return shipping_option.id === ((_a = state.cart) === null || _a === void 0 ? void 0 : _a.shipping_option_id); })) !== null && _a !== void 0 ? _a : null; },
    currentShippingIdentifier: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.shipping_option_id) !== null && _b !== void 0 ? _b : null; },
    currentPaymentType: (state) => { var _a; return (_a = state.payment_types.find((payment_type) => { var _a; return payment_type.id === ((_a = state.cart) === null || _a === void 0 ? void 0 : _a.payment_type_id); })) !== null && _a !== void 0 ? _a : null; },
    currentProducts: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.products) !== null && _b !== void 0 ? _b : []; },
    brutto_products: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.totals.brutto_products) !== null && _b !== void 0 ? _b : 0; },
    shippingAddress: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.addresses.shipping) !== null && _b !== void 0 ? _b : null; },
    useBillingAddress: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.use_billing_address) !== null && _b !== void 0 ? _b : null; },
    billingAddress: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.addresses.billing) !== null && _b !== void 0 ? _b : null; },
    currentCouponCode: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.voucher_code) !== null && _b !== void 0 ? _b : ''; },
    cartTotals: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.totals) !== null && _b !== void 0 ? _b : null; },
    email: (state) => { var _a, _b; return (_b = (_a = state.cart) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : ''; },
    bluePaymentMethods: (state) => state.bluePaymentMethods,
    currentProduct: (state) => state.currentProduct,
    getEmarsysRecos: (state) => state.recos,
    currentListedProducts: (state) => state.currentProducts,
    currentPageInfo: (state) => state.currentPageInfo,
    currentSorting: (state) => state.currentSorting,
    availableFilters: (state) => state.availableFilters,
    appliedFilters: (state) => state.appliedFilters,
    priceBars: (state) => state.priceBars,
    getListingType: (state) => state.listingType,
};

import { defineComponent } from 'vue';
import eventBus from '@/services/EventBus';
import SingleCondition from '@/components/PopUps/ConditionsPopup/SingleCondition.vue';
export default defineComponent({
    name: 'conditions-popup',
    components: { SingleCondition },
    data() {
        return {
            openPopup: false,
            entries: [
                {
                    question: 'Neu',
                    image: '/images/_new_brand/infoPageContent/condition_images/condition_new.png',
                    answer: 'Das Gerät ist neu, unbenutzt und befindet sich in der ungeöffneten, versiegelten Originalverpackung inkl. des kompletten Originallieferumfangs. Sobald das Herstellersiegel gebrochen oder ein gebrochenes durch ein neues Siegel überklebt wurde, gilt das Gerät als „wie neu“. ',
                },
                {
                    question: 'Wie neu',
                    image: '/images/_new_brand/infoPageContent/condition_images/condition_wie_neu.png',
                    answer: 'Das Gerät wurde kaum bis gar nicht benutzt und befindet sich in einem hervorragenden Zustand ohne Gebrauchsspuren. Beim Verkauf an uns muss die Originalverpackung vorhanden sein.',
                },
                {
                    question: 'Sehr gut',
                    image: '/images/_new_brand/infoPageContent/condition_images/condition_sehr_gut.png',
                    answer: 'Das Gerät befindet sich in einem sehr gepflegten Zustand und hat wenige, leichte, kaum sichtbare Gebrauchsspuren. Dazu zählen zum Beispiel oberflächliche Kratzer, die bei eingeschaltetem Display nicht zu erkennen sind.',
                },
                {
                    question: 'Gut',
                    image: '/images/_new_brand/infoPageContent/condition_images/condition_gut.png',
                    answer: 'Das Gerät ist gut gepflegt und weist nur wenige Abnutzungsspuren normaler Nutzung auf. Leichte bis mittlere Gebrauchsspuren wie sichtbare Kratzer am Gehäuse, Farbabrieb an den Kanten oder kleine Dellen sind zulässig, sofern sie die Funktionalität nicht beeinflussen. Ebenso können feine Kratzer auf dem Display vorhanden sein.',
                },
                {
                    question: 'Akzeptabel',
                    image: '/images/_new_brand/infoPageContent/condition_images/condition_akzeptabel.png',
                    answer: 'Das Gerät hat deutliche Gebrauchsspuren wie zum Beispiel stärkere Kratzer, Dellen oder Stoßspuren. Ebenso können sicht- und spürbare Kratzer auf dem Display vorhanden sein. Diese mittleren bis starken Gebrauchsspuren haben jedoch keinen Einfluss auf die Funktionalität.',
                },
            ],
        };
    },
    mounted() {
        eventBus.on('conditionsPopup', () => {
            this.openPopup = true;
        });
    },
});
// POPUPS wohnen in der popups.html.twig !!!!
// Button-Sample zum öffnen eines, bzw. dieses Popups:
// <open-popup-button popup-name="conditionsPopup"><div class="buttonBase buttonGreen">Zustände</div></open-popup-button>

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7238a160"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "col-md-4 col-12 [ mb-md-45 mb-3 ]" };
const _hoisted_2 = ["href", "title"];
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = {
    key: 0,
    class: "font5-responsive mb-3"
};
const _hoisted_5 = { class: "d-block h5-responsive text-underline font-italic [ mb-sm-3 mb-2 ]" };
const _hoisted_6 = { class: "font7-responsive mb-4" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
            href: `${_ctx.article_path}/${_ctx.article.id}`,
            title: `Zum Artikel: ${_ctx.article.title}`,
            class: "box d-block border rounded bg-white px-lg-4 px-3 pt-lg-4 pt-3 pb-sm-45 pb-3 h-100 text-decoration-none"
        }, [
            _createElementVNode("img", {
                loading: "lazy",
                src: _ctx.optimizeImage(_ctx.article.image),
                class: "mb-45 border",
                "data-max-width": "672",
                alt: _ctx.article.title,
                style: { "aspect-ratio": "1 / 0.7", "width": "100%", "object-fit": "cover" }
            }, null, 8, _hoisted_3),
            (_ctx.article.publisher)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.article.publisher), 1))
                : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.article.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.dateString), 1)
        ], 8, _hoisted_2)
    ]));
}

export var ShippingType;
(function (ShippingType) {
    ShippingType["pickup"] = "pickup";
    ShippingType["self_sent"] = "self-sent";
})(ShippingType || (ShippingType = {}));
export function transformToShippingOptions(data) {
    return Object.assign({}, data);
}
export function transformShippingOption(data) {
    const option = Object.assign({}, data);
    if (option.type === ShippingType.self_sent) {
        return option;
    }
    if (option.pickup_date) {
        option.pickup_date = new Date(option.pickup_date);
    }
    return option;
}

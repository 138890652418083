import { computed, defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';
export default defineComponent({
    name: 'base-input',
    props: {
        name: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            required: true,
        },
        inputType: {
            type: String,
            required: false,
            default: 'text',
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        autocomplete: {
            type: String,
            required: false,
            default: 'on',
        },
        rules: {
            type: Object,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: null,
        },
    },
    emits: ['changed', 'blur', 'update:modelValue'],
    setup(props, { emit }) {
        const { value: inputValue, handleBlur: veeHandleBlur, handleChange: veeHandleChanges, meta, errorMessage, setErrors, validate, } = useField(props.name, props.rules, {
            initialValue: props.modelValue,
        });
        watch(() => props.modelValue, (newValue) => {
            inputValue.value = newValue;
        });
        // need initial error set for onload
        setErrors(props.errors);
        watch(() => props.errors, (errors) => {
            if (errors && meta.validated && meta.valid) {
                setErrors(errors);
            }
            else {
                setErrors([]);
                validate();
            }
        });
        watch(() => meta.pending, (pending) => {
            if (!pending) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const event = { value: inputValue.value, valid: meta.valid };
                emit('changed', event);
            }
        });
        const handleBlur = function (event) {
            veeHandleBlur(event);
            emit('blur', event);
        };
        const handleChange = function (event, shouldValidate) {
            veeHandleChanges(event, shouldValidate);
            if (event) {
                emit('update:modelValue', event.target.value);
            }
        };
        const fieldType = computed(() => props.inputType);
        return {
            handleChange,
            handleBlur,
            inputValue,
            meta,
            fieldType,
            errorMessage,
        };
    },
});

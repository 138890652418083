var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent } from 'vue';
import ArticleCard from '@/components/StaticPages/Press/ArticleCard.vue';
import httpClient from '@/services/api/axios';
export default defineComponent({
    name: 'article-cards-with-more-button',
    props: {
        article_type: {
            required: true,
            type: String,
        },
        show_more_button: {
            required: true,
            type: Boolean,
        },
        initial_pages: {
            required: true,
            type: Number,
        },
        page_size: {
            required: true,
            type: Number,
        },
    },
    components: { ArticleCard },
    data() {
        return {
            articles: [],
            page: 0,
            total: 0,
        };
    },
    methods: {
        loadArticles() {
            return httpClient
                .get('/api/articles', {
                params: {
                    article_type: this.article_type,
                    page_size: this.page_size,
                    page: this.page,
                },
            })
                .then((response) => {
                this.articles.push(...response.data.data);
                this.total = response.data.meta.total;
                return true;
            });
        },
        loadMoreArticles() {
            this.page++;
            this.loadArticles();
        },
    },
    computed: {
        articlePath() {
            switch (this.article_type) {
                case 'presse':
                    return '/presse/pressemitteilung';
                case 'pressespiegel':
                    return '/presse/pressespiegel';
                case 'blog':
                    return '/blog';
                default:
                    return 'ERROR_PFAD_FEHLT_SORRY';
            }
        },
        showButton() {
            return this.show_more_button && this.articles.length < this.total;
        },
    },
    beforeMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let i = 1;
            while (i <= this.initial_pages) {
                this.page++;
                yield this.loadArticles();
                i++;
            }
        });
    },
});

export default class FacebookTrackingService {
    trackProductShow(product) {
        this.track('ViewContent', {
            value: product.currentPrice,
            currency: 'EUR',
            content_type: 'product',
            content_ids: product.cArtNr,
        });
    }
    trackCartAdd(product, count) {
        this.track('AddToCart', {
            value: product.currentPrice,
            currency: 'EUR',
            content_type: 'product',
            contents: [
                {
                    id: product.cArtNr,
                    quantity: count,
                },
            ],
        });
    }
    track(action, payload) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (typeof window.fbq === 'undefined') {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.fbq('track', action, payload);
    }
}

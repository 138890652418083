export const redMutations = {
    SET_CART(state, cart) {
        state.cart = cart;
    },
    SET_COUPONS(state, coupons) {
        state.coupons = coupons;
    },
    SET_VALID_CART(state, is_valid) {
        state.valid_for_checkout = is_valid;
    },
    SET_PICKUP_DATES(state, pickupDates) {
        state.pickup_dates = pickupDates;
    },
    SET_PAYOUT_OPTIONS(state, payout_options) {
        state.payout_options = payout_options;
    },
    SET_SHIPPING_OPTIONS(state, shipping_options) {
        state.shipping_options = shipping_options;
    },
    SET_CART_ERRORS(state, cart_errors) {
        state.cart_errors = cart_errors;
    },
};

window.throttle = function (fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function () {
        var context = scope || this;

        var now  = +new Date(),
            args = arguments;
        if (last && now < last + threshhold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshhold);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
};

window.showLoadingAnimation = function () {
    $('#loadingAni').show();
    $('#grayish_overlay').removeClass('hidden');
};

window.hideLoadingAnimation = function () {
    $('#loadingAni').hide();
    $('#grayish_overlay').addClass('hidden');
};

$(document).on('submit', '#product_buy_search', function (e) {
    e.preventDefault();
});

$(() => {
    // READ-MORE-LINK JS *****************************************************************
    let rmArticles = $('.readMore');

    let readMoreLink = '<div class="[ JS_readMoreLinkStyleClose ]">';
    readMoreLink += '  <a href="#" class="[ JS_readMore ] mt-4">Mehr lesen ...</a>';
    readMoreLink += '</div>';

    rmArticles.append(readMoreLink);
    rmArticles.addClass('readMoreFadeOut');

    $(document).on('click', '.JS_readMore', function (e) {
        e.preventDefault();

        let currentReadMoreLinkWrapper = $(this).parent('div');
        let currentArticle = currentReadMoreLinkWrapper.parents('.readMore');

        currentReadMoreLinkWrapper.toggleClass('JS_readMoreLinkStyleClose').toggleClass('JS_readMoreLinkStyleOpen');
        currentArticle.toggleClass('readMoreFadeOut').toggleClass('readMoreFadeIn');
        $(this).toggleClass('expanded');

        let text = 'Mehr lesen ...';

        if ($(this).hasClass('expanded')) {
            text = 'Weniger anzeigen';
        }

        $(this).text(text);
    });
});


$(document).on('click', '.JS_dropDownOpenLink', function (e) {


    let icon = $(this).parents('.JS_dropdownBox').find('.JS_dropDownIconRotate');
    let currentBox = $(this).parents('.JS_dropdownBox');
    let dropdown = currentBox.find('.JS_dropdown');

    if (dropdown.hasClass('JS_dropdownHide')) {
        dropdown.removeClass('JS_dropdownHide').addClass('JS_dropdownShow').fadeIn();
        icon.css({transition: '0.3s', transform: 'rotate(180deg)'});
    } else if (dropdown.hasClass('JS_dropdownShow')) {
        dropdown.removeClass('JS_dropdownShow').addClass('JS_dropdownHide').fadeOut();
        icon.css({transition: '0.3s', transform: 'rotate(0deg)'});
    }
});

$(() => {

    window.eventBus.on('ShowLoadingOverlay', function () {
        window.showLoadingAnimation();
    });
    window.eventBus.on('HideLoadingOverlay', function () {
        window.hideLoadingAnimation();
    });
    // Dropdown-Box allgemein (z.B. bei Passwort ändern -> aufklappen)
    $('.JS_dropdownShow')
        .fadeIn()
        .parent('.JS_dropdownBox')
        .find('.JS_dropDownIconRotate')
        .css({transition: '0.3s', transform: 'rotate(180deg)'});
    $('.JS_dropdownHide')
        .fadeOut()
        .parent('.JS_dropdownBox')
        .find('.JS_dropDownIconRotate')
        .css({transition: '0.3s', transform: 'rotate(0deg)'});
});


window.setCookie = (name, value, expires_in_days) => {
    const d = new Date();
    d.setTime(d.getTime() + (expires_in_days * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    let cookie_data = name + '=' + value + ';' + expires + ';path=/';
    document.cookie = cookie_data;
};

//

function onKonamiCode(cb) {
    var input = '';
    var key = '38384040373937396665';
    document.addEventListener('keydown', function (e) {
        input += ('' + e.keyCode);
        if (input === key) {
            return cb();
        }
        if (!key.indexOf(input)) {
            return;
        }
        input = ('' + e.keyCode);
    });
}

onKonamiCode(function () {

    console.log('winner!!!!');
    window.setCookie('darkmode', document.cookie.includes('darkmode') ? '' : 'yes please', document.cookie.includes('darkmode') ? 0 : 7);
    location.reload();
});
import { defineComponent } from 'vue';
import eventBus from '@/services/EventBus';
export default defineComponent({
    name: 'open-popup-button',
    props: {
        popupName: {
            required: true,
            type: String,
        },
    },
    methods: {
        openPopUp() {
            eventBus.emit(this.popupName);
        },
    },
});
//  // Den Button zum Öffnen des Templates:
//  // (Achtung, Popups sind nicht immer auf allen Seiten verfügbar.
//  // Der Button kann nicht im "BLUE" liegen und ein Popup aus "RED" öffnen):
// <open-popup-button popupName="guaranteePopup">
//     // Beliebiges Icon oder Button zum klicken, hier in den Slot
//     <div class="buttonBase buttonGreen">Klick mich</div>
// </open-guarantee-popup-button>

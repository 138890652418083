import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href", "title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("a", {
        href: _ctx.url,
        target: "_blank",
        title: _ctx.title,
        class: "buttonBase"
    }, _toDisplayString(_ctx.title), 9, _hoisted_1));
}

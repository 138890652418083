import { defineComponent } from 'vue';
export default defineComponent({
    name: 'google-maps-navigation-button',
    props: {
        location: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: 'In Maps öffnen',
        },
    },
    computed: {
        url() {
            return 'https://www.google.com/maps?saddr=My+Location&daddr=' +
                this.location.street_name +
                ' ' +
                this.location.street_number +
                ' ' +
                this.location.zip_code +
                ' ' +
                this.location.city;
        },
    },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "row [ pt-8 pb-md-10 pb-3 ] font6-responsive" };
const _hoisted_2 = {
    key: 0,
    class: "col-12 text-center mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_article_card = _resolveComponent("article-card");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article, index) => {
            return (_openBlock(), _createBlock(_component_article_card, {
                key: index,
                article: article,
                article_path: _ctx.articlePath
            }, null, 8, ["article", "article_path"]));
        }), 128)),
        (_ctx.showButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("button", {
                    title: "Mehr anzeígen",
                    class: "buttonBase buttonGreen",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.loadMoreArticles && _ctx.loadMoreArticles(...args)))
                }, " Weitere Artikel ansehen ")
            ]))
            : _createCommentVNode("", true)
    ]));
}

import { blueState } from './state';
import { blueGetters } from './getters';
import { blueMutations } from './mutations';
import { blueActions } from './actions';
export const blueModule = {
    actions: blueActions,
    getters: blueGetters,
    mutations: blueMutations,
    state: blueState,
    namespaced: true,
};

import { defineComponent } from 'vue';
import axios from 'axios';
import BaseInput from '@/components/Base/Forms/Inputs/BaseInput.vue';
import { stringRequiredRule } from '@/helpers/ValidationRules';
import BaseEmailInput from '@/components/Base/Forms/Inputs/BaseEmailInput.vue';
import eventBus from '@/services/EventBus';
export default defineComponent({
    name: 'repair-request-form',
    components: { BaseEmailInput, BaseInput },
    data() {
        return {
            formValues: {
                name: '',
                phoneNumber: '',
                email: '',
                device: '',
                shop: '',
                damageType: '',
                damageTypeInfoText: '',
            },
        };
    },
    computed: {
        stringRequiredRule() {
            return stringRequiredRule.clone().default('');
        },
    },
    methods: {
        sendRepairRequestMail() {
            axios
                .post('/repair-contact', {
                name: this.formValues.name,
                phoneNumber: this.formValues.phoneNumber,
                email: this.formValues.email,
                device: this.formValues.device,
                shop: this.formValues.shop,
                damageType: this.formValues.damageType,
                damageTypeInfoText: this.formValues.damageTypeInfoText,
            })
                .then(() => {
                eventBus.emit('openInfoPopup', {
                    icon: 'success',
                    headline: 'Versand bestätigt',
                    message: 'Vielen Dank für ihre Anfrage!',
                });
                this.formValues.name = '';
                this.formValues.phoneNumber = '';
                this.formValues.email = '';
                this.formValues.device = '';
                this.formValues.shop = '';
                this.formValues.damageType = '';
                this.formValues.damageTypeInfoText = '';
            })
                .catch((error) => {
                let serverResponseErrors = '';
                Object.values(error.response.data.errors).forEach((e) => {
                    serverResponseErrors += e;
                    serverResponseErrors += '<br />';
                });
                eventBus.emit('openInfoPopup', {
                    icon: 'error',
                    headline: 'Da ging was schief',
                    message: serverResponseErrors,
                });
            });
        },
    },
});

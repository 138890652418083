import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { de, en } from 'vuetify/locale';
import myswooopTheme from '@/vuetify/theme';
import { aliases, myswooopIconSet } from '@/vuetify/icons';
const vuetify = createVuetify({
    components,
    directives,
    defaults: {
        global: {
            elevation: 0,
        },
        VBtn: {
            color: 'primary',
            variant: 'flat',
        },
        VTextField: {
            variant: 'outlined',
        },
    },
    icons: {
        defaultSet: 'myswooop',
        aliases,
        sets: {
            myswooop: myswooopIconSet,
        },
    },
    theme: {
        defaultTheme: 'myswooop',
        themes: {
            myswooop: myswooopTheme,
        },
    },
    locale: {
        locale: 'de',
        fallback: 'en',
        messages: { de, en },
    },
});
export default vuetify;

import { createStore } from 'vuex';
import { blueModule } from './modules/blue';
import { redModule } from './modules/red';
import { accountModule } from './modules/account';
import { globalModule } from '@/store/modules/global';
export const store = createStore({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        account: accountModule,
        blue: blueModule,
        global: globalModule,
        red: redModule,
    },
});
export function useStore() {
    return store;
}

export var AddressTypes;
(function (AddressTypes) {
    AddressTypes["shipping"] = "shipping";
    AddressTypes["billing"] = "billing";
})(AddressTypes || (AddressTypes = {}));
export const compareAddresses = (first_address, second_address) => {
    if ((first_address === null || first_address === void 0 ? void 0 : first_address.id) && (second_address === null || second_address === void 0 ? void 0 : second_address.id)) {
        return first_address.id === second_address.id;
    }
    if (first_address.first_name !== second_address.first_name ||
        first_address.last_name !== second_address.last_name) {
        return false;
    }
    if (first_address.street_name !== second_address.street_name ||
        first_address.street_number !== second_address.street_number ||
        first_address.postcode !== second_address.postcode ||
        first_address.city !== second_address.city) {
        return false;
    }
    return first_address.company === second_address.company;
};

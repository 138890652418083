import { defineComponent } from 'vue';
import eventBus from '@/services/EventBus';
export default defineComponent({
    name: 'header-advantage-entry',
    props: {
        slide: {
            type: Object,
            required: true,
        },
    },
    methods: {
        runClick() {
            if (this.slide.link) {
                eventBus.emit(this.slide.link);
            }
        },
    },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-94d8cf54"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["id"];
const _hoisted_3 = { class: "questions text-center w-100" };
const _hoisted_4 = {
    key: 0,
    class: "faqHeadline h2-responsive mb-sm-5 mb-3 text-sm-center text-left"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_faq_entry = _resolveComponent("faq-entry");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (!_ctx.json_ld_only)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faq_list, (list, parent_index) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: parent_index,
                        id: `faqTitle${list.title}`,
                        class: _normalizeClass(["faqList", `row no-gutters w-100 FaqList ${_ctx.faq_list.length > 1 ? 'mb-5' : ''}`])
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            (list.title)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(list.title), 1))
                                : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.items, (faq, index) => {
                                return (_openBlock(), _createBlock(_component_faq_entry, {
                                    question: faq.question,
                                    answer: faq.answer,
                                    image: faq.image,
                                    anker: `AQc${parent_index}i${index}`,
                                    offset: _ctx.calculatedOffsets,
                                    key: index
                                }, null, 8, ["question", "answer", "image", "anker", "offset"]));
                            }), 128))
                        ])
                    ], 10, _hoisted_2));
                }), 128))
            ]))
            : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
            innerHTML: _ctx.jsonld,
            type: "application/ld+json"
        }, null, 8, ["innerHTML"]))
    ], 64));
}

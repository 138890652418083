import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
    name: 'logos-our-partners',
    data() {
        return {
            // link nuss leer sein oder gesetzt, nicht löschen!
            logos: [
                {
                    path: 'images/_new_brand/infoPageContent/it-ankauf/logos/logo_NKD.png',
                    alt: 'NKD',
                    link: '',
                    title: 'NKD',
                    extraStyling: 'height: 30px; max-width: 160px; object-fit: contain;',
                },
                {
                    path: 'images/_new_brand/infoPageContent/it-ankauf/logos/logo_Nehlsen.png',
                    alt: 'Nehlsen',
                    link: '',
                    title: 'Nehlsen',
                    extraStyling: 'height: 30px; max-width: 160px; object-fit: contain;',
                },
                {
                    path: 'images/_new_brand/infoPageContent/it-ankauf/logos/logo_MEWA.png',
                    alt: 'MEWA',
                    link: '',
                    title: 'MEWA',
                    extraStyling: 'height: 30px; max-width: 160px; object-fit: contain;',
                },
                {
                    path: 'images/_new_brand/infoPageContent/it-ankauf/logos/logo_IGEL.png',
                    alt: 'IGEL',
                    link: '',
                    title: 'IGEL',
                    extraStyling: 'height: 30px; max-width: 160px; object-fit: contain;',
                },
                {
                    path: 'images/_new_brand/infoPageContent/it-ankauf/logos/logo_WELLBROCK.png',
                    alt: 'WELLBROCK',
                    link: '',
                    title: 'WELLBROCK',
                    extraStyling: 'height: 30px; max-width: 160px; object-fit: contain;',
                },
                {
                    path: 'images/_new_brand/infoPageContent/it-ankauf/logos/logo_CleverShuttle.png',
                    alt: 'CleverShuttle',
                    link: '',
                    title: 'CleverShuttle',
                    extraStyling: 'height: 30px; max-width: 160px; object-fit: contain;',
                },
                {
                    path: 'images/_new_brand/infoPageContent/it-ankauf/logos/logo_HD+.png',
                    alt: 'HD+',
                    link: '',
                    title: 'HD+',
                    extraStyling: 'height: 30px; max-width: 160px; object-fit: contain;',
                },
            ],
        };
    },
    computed: Object.assign({}, mapGetters('global', {
        isMobile: 'isMobile',
    })),
});

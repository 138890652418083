export var CouponType;
(function (CouponType) {
    CouponType["RED"] = "red";
    CouponType["BLUE"] = "blue";
})(CouponType || (CouponType = {}));
export var CouponValueType;
(function (CouponValueType) {
    CouponValueType["FIXED"] = "fixed";
    CouponValueType["PERCENT"] = "percent";
    CouponValueType["FREE_SHIPPING"] = "shipping";
})(CouponValueType || (CouponValueType = {}));
export function transformToCoupon(data) {
    const coupon = Object.assign({}, data);
    if (data.expire_date) {
        coupon.expire_date = new Date(data.expire_date);
    }
    return coupon;
}

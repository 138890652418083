import { globalState } from './state';
import { globalGetters } from './getters';
import { globalMutations } from './mutations';
import { globalActions } from '@/store/modules/global/actions';
export const globalModule = {
    actions: globalActions,
    getters: globalGetters,
    mutations: globalMutations,
    state: globalState,
    namespaced: true,
};

window.pseudohistorycounter = 0;
// eslint-disable-next-line no-undef
$(window).on('popstate', function (event) {
    if (window.pseudohistorycounter > 0) {
        // eslint-disable-next-line no-undef
        swal.close();
    }
    while (window.pseudohistorycounter > 1 && window.history.state === -1) {
        window.pseudohistorycounter--;
        history.back();
    }
});

window.renderAsModal = function (html, extraclass, ignoreStack = false) {
    window.pseudohistorycounter++;

    window.history.pushState(-1, null, null);

    var myhtml = document.createElement('div');
    myhtml.innerHTML = html;
    // eslint-disable-next-line no-undef
    swal(
        {
            title:     '',
            content:   myhtml,
            buttons:   false,
            className: extraclass,
        },
        ignoreStack,
    );

    $(window).trigger('scroll');
};

// SIDEBAR for i.e MyAccount
window.renderAsSidebar = function (html) {
    window.renderAsModal(html, 'sidebar');
    // eslint-disable-next-line no-undef
    $('main.myAccount').addClass('JS_beDynamicModalIsOpen');
};
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tooltip';
// re-patch inputs
function startJquery() {
    const inputs = document.getElementsByTagName('input');
    for (const input of inputs) {
        const attributeNames = input.getAttributeNames();
        if (attributeNames.find((name) => {
            return name.match(/^data-v-/);
        }) === undefined) {
            input.setAttribute('value', input.value);
        }
    }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.jQuery = window.$ = require('jquery');
if (document.readyState !== 'loading') {
    startJquery();
}
//help it a bit... for whatever reason
document.addEventListener('readystatechange', () => {
    if (document.readyState == 'interactive') {
        startJquery();
    }
});

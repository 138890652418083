import { defineComponent } from 'vue';
import euroFormatter from '@/services/formattter/Currency';
export default defineComponent({
    name: 'formatted-price',
    props: {
        price: {
            type: Number,
            required: true,
        },
    },
    computed: {
        formattedPrice() {
            return euroFormatter.format(this.price);
        },
    },
});

import BaseApiClient from '@/services/api/BaseApiClient';
export default class FilterApiClient extends BaseApiClient {
    getAvailableFilters(listingType, listingValue) {
        let query = `api/blue/availableFilters?listingType=${listingType}`;
        if (listingValue) {
            query += `&listingValue=${listingValue}`;
        }
        return this.http_client.get(query).then((response) => response.data);
    }
    getPriceBars(listingType, listingValue) {
        let query = `api/blue/priceBars?listingType=${listingType}`;
        if (listingValue) {
            query += `&listingValue=${listingValue}`;
        }
        return this.http_client.get(query).then((response) => response.data);
    }
    getAttributesForModel(modelId) {
        return this.get(`/api/blue/product/attributes/${modelId}`).then((response) => {
            return response.data;
        });
    }
}

import { defineComponent } from 'vue';
import BaseInput from '@/components/Base/Forms/Inputs/BaseInput.vue';
import { emailRequiredRule, emailRule } from '@/helpers/ValidationRules';
export default defineComponent({
    name: 'base-email-input',
    components: { 'base-input': BaseInput },
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: 'E-Mail',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Deine E-Mail',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: null,
        },
    },
    emits: ['changed', 'update:modelValue'],
    computed: {
        emailValue: {
            get() {
                return this.modelValue;
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
        rule: function () {
            return this.required ? emailRequiredRule : emailRule;
        },
    },
    methods: {
        handleChangedEvent(event) {
            this.$emit('changed', event);
        },
    },
});

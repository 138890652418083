import { defineComponent } from 'vue';
import dateFormatter from '@/services/formattter/Date';
export default defineComponent({
    name: 'formatted-date',
    props: {
        date: {
            type: Date,
            required: true,
        },
    },
    computed: {
        formattedDate() {
            return dateFormatter.format(this.date);
        },
    },
});

import eventBus from '@/services/EventBus';

window.formatPrice = function (number, currency) {
    var shortNumber = parseFloat(number).toFixed(2);
    shortNumber += '';
    let x = shortNumber.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }
    return x1 + x2 + ' ' + currency;
};

window.getToken = () => {
    return document.getElementById('JS-CSRF-TOKEN').value;
};

window.initTooltips = () => {
    $('[data-toggle="tooltip"]').tooltip({
        container: 'body',
    });
};

$(() => {
    $(document).on('click', '.newsletter_abo', function (e) {
        var mail = $(this).parents('.input-group').find('input').val();
        if (mail) {
            $.put("/newsletter/", {email: mail}).done(function (data) {

                eventBus.emit('openInfoPopup', {
                    icon: 'success',
                    headline: 'Newsletter',
                    message: 'Du hast erfolgreich den Newsletter abonniert! Schau in deinem Postfach nach deinem Gutscheincode.',
                });

            }).fail(function (data) {

                eventBus.emit('openInfoPopup', {
                    icon: 'error',
                    headline: 'Newsletter',
                    message: 'Du hast dich anscheinend schon mit dieser E-Mail Adresse für den Newsletter eingetragen oder die E-Mail Adresse ist nicht korrekt.',
                });
            });
        }
    });
});

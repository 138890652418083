import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_base_input = _resolveComponent("base-input");
    return (_openBlock(), _createBlock(_component_base_input, {
        id: _ctx.id,
        name: _ctx.name,
        modelValue: _ctx.emailValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.emailValue) = $event)),
        label: _ctx.label,
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        rules: _ctx.rule,
        "input-type": "email",
        onChanged: _ctx.handleChangedEvent,
        errors: _ctx.errors
    }, null, 8, ["id", "name", "modelValue", "label", "placeholder", "disabled", "readonly", "rules", "onChanged", "errors"]));
}

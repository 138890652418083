$.fn.isInViewport = function () {
    if (typeof $(this).offset() !== 'undefined') {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + window.innerHeight;

        return elementBottom > viewportTop && elementTop < viewportBottom;
    }
    return false;
};

$.fn.optimisticIsInViewport = function () {
    if (typeof $(this).offset() !== 'undefined') {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + 2 * window.innerHeight;

        return elementBottom >= viewportTop && elementTop < viewportBottom;
    }
    return false;
};

// Override $.post
(function () {
    // Store a reference to the original remove method.
    var originalPostMethod = jQuery.post;

    // Define overriding method.
    jQuery.post = function (data) {
        //extend our data to contain token always
        if (arguments[1] && typeof arguments[1] === 'object') {
            arguments[1]._token = getToken();
        } else if (typeof arguments[1] === 'string') {
            //skip
        } else {
            [].push.call(arguments, { _token: getToken() });
        }
        return originalPostMethod.apply(this, arguments);
    };
})();

// create missing ajax shorthands
$.put = function (data) {
    if (arguments[1]) {
        arguments[1]._token = getToken();
    } else {
        [].push.call(arguments, { _token: getToken() });
    }
    return $.ajax({ url: arguments[0], method: 'PUT', data: arguments[1] });
};

$.delete = function (data) {
    if (arguments[1]) {
        arguments[1]._token = getToken();
    } else {
        [].push.call(arguments, { _token: getToken() });
    }
    return $.ajax({ url: arguments[0], method: 'DELETE', data: arguments[1] });
};

$.patch = function (data) {
    if (arguments[1]) {
        arguments[1]._token = getToken();
    } else {
        [].push.call(arguments, { _token: getToken() });
    }
    return $.ajax({ url: arguments[0], method: 'PATCH', data: arguments[1] });
};

$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

$.fn.shake = function (interval, distance, times, direction) {
    interval = typeof interval == 'undefined' ? 100 : interval;
    distance = typeof distance == 'undefined' ? 10 : distance;
    times = typeof times == 'undefined' ? 3 : times;
    direction = typeof direction == 'undefined' ? 'y' : direction;

    var jTarget = $(this);
    jTarget.css('position', 'relative').addClass('wasShaked');

    if (direction === 'x') {
        for (var iter = 0; iter < times + 1; iter++) {
            jTarget.animate({ left: iter % 2 == 0 ? distance : distance * -1 }, interval);
        }
        return jTarget.animate({ left: 0 }, interval);
    } else {
        for (var iter = 0; iter < times + 1; iter++) {
            jTarget.animate({ top: iter % 2 == 0 ? distance : distance * -1 }, interval);
        }
        return jTarget.animate({ top: 0 }, interval);
    }
};

// VON OBEN NACH UNTEN
$.fn.shakeInViewport = function (elementToShake) {
    elementToShake = $(document).find(elementToShake);
    $(window).scroll(function () {
        if (elementToShake.isInViewport() && !elementToShake.hasClass('wasShaked')) {
            return elementToShake.shake(150, 2);
        }
    });
};

// VON LINKS NACH RECHTS
$.fn.shakeInViewportBad = function (elementToShake) {
    elementToShake = $(document).find(elementToShake);
    $(window).scroll(function () {
        if (elementToShake.isInViewport() && !elementToShake.hasClass('wasShaked')) {
            return elementToShake.shake(150, 2, 3, 'x');
        }
    });
};

$.fn.scrollTo = function (speed, offsetTop) {
    if (typeof speed === 'undefined') {
        speed = 1000;
    }

    if (typeof offsetTop === 'undefined') {
        offsetTop = 0;
    }

    $('html, body').animate(
        {
            scrollTop: parseInt($(this).offset().top - offsetTop),
        },
        speed
    );
};

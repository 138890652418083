import eventBus from '@/services/EventBus';
import axios from '@/services/api/axios';
import popupManager from '@/services/Dom/PopupManager';
import { useStore } from '@/store';
class AuthService {
    needsLogin(target) {
        let intent = null;
        if (target.hasAttribute('href')) {
            intent = target.getAttribute('href');
        }
        axios.post('/redirector', { url: intent !== null && intent !== void 0 ? intent : '/' }).then(() => {
            eventBus.emit('openLoginPopup');
        });
    }
    loggedIn() {
        const elements = document.querySelectorAll('.needs_login');
        elements.forEach((element) => {
            element.classList.remove('needs_login');
        });
        setTimeout(() => {
            // check if user used plain pages instead of popup
            switch (location.pathname) {
                case '/login':
                case '/auth/login':
                case '/auth/register':
                    window.location.replace('/');
                    return;
                default:
                    return;
            }
        }, 800);
        axios.get('/redirector').then((response) => {
            if (response.data.intent && response.data.intent !== window.location.href) {
                window.location.href = response.data.intent;
                return;
            }
        });
        const store = useStore();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.dispatch('account/PULL_USER', null);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.dispatch('account/LOAD_WISHLIST', null);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.dispatch('blue/PULL_CART', null);
        // Close all open windows:
        setTimeout(() => {
            popupManager.hidePopup();
            eventBus.emit('closeLoginPopup');
            eventBus.emit('closeInfoPopup');
        }, 1500);
    }
}
const authService = new AuthService();
export default authService;

import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default defineComponent({
    name: 'full-slider-vue',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    props: {
        slides: {
            type: Array,
            required: true,
        },
    },
    data: () => ({}),
    computed: Object.assign({}, mapGetters('global', {
        isMobile: 'isMobile',
    })),
});

import { defineComponent } from 'vue';
export default defineComponent({
    name: 'single-condition',
    data() {
        return {
            expanded: false,
        };
    },
    props: {
        question: {
            type: String,
            required: true,
        },
        answer: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
    },
    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded;
        },
    },
});

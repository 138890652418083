import { defineComponent } from 'vue';
import optimizeImage from '@/services/ImageOptimize';
export default defineComponent({
    name: 'article-card',
    methods: { optimizeImage },
    props: {
        article: {
            type: Object,
            required: true,
        },
        article_path: {
            type: String,
            required: true,
        },
    },
    computed: {
        dateString() {
            const date = new Date(this.article.published_at);
            return date.toLocaleDateString('de-DE', { timeZone: 'Europe/Berlin' });
        },
    },
});

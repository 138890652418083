import { useStore } from '@/store';
export default function optimizeImage(src, maxHeight, maxWidth) {
    if (src === null) {
        src = 'https://cdn.myschubert.com/images/myswooop_placeholder.png';
    }
    const fileType = src.substring(src.lastIndexOf('.') + 1);
    if (fileType.match(RegExp(/(gif)/, 'i'))) {
        return src;
    }
    const webP = useStore().getters['global/canHandleWebp'];
    const format = webP ? 'webp' : fileType;
    let uri = `/optimized/img?source=${src}&format=${format}`;
    if (maxHeight) {
        uri = uri + '&max-height=' + maxHeight;
    }
    if (maxWidth) {
        uri = uri + '&max-width=' + maxWidth;
    }
    return uri;
}

import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import FormattedPrice from '@/components/Base/Formats/FormattedPrice.vue';
export default defineComponent({
    name: 'ProductBoxSmall',
    components: { FormattedPrice },
    props: {
        index: {
            type: Number,
            required: true,
        },
        cartItem: {
            type: Object,
            required: true,
        },
    },
    computed: {
        price() {
            return this.cartItem.price;
        },
    },
    methods: Object.assign({ removeProduct() {
            this.removeCartItem(this.cartItem.id);
        } }, mapActions('blue', {
        removeCartItem: 'REMOVE_PRODUCT',
    })),
});

// FANCY SELECT FIELD ********************************************************

$(document).on('click', '.JS_fancySelectField', function (e) {
    $(this).parents('.fancySelectWrapper').find('.JS_fancyOptionsWrapper').toggleClass('d-none');
});

$(document).on('click', '.JS_fancyOptionsWrapper .JS_fancyOptionField', function (e) {
    e.preventDefault();

    if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        resetSelectField($(this));
    } else {
        // RESET all Option Fields
        resetAllOptionFields($(this));

        // Dieses Option Feld als aktives darstellen
        activeOptionFieldAction($(this));

        // Select Field füllen mit aktuellem Option-Wert/Text + Styling
        fillSelectField($(this));

        // Feldname einblenden
        showSelectFieldName($(this));

        // Options Wrapper wieder ausblenden
        $(this).parents('.JS_fancyOptionsWrapper').addClass('d-none');
    }

    $(this)
        .parents('.JS_fancyOptionsWrapper')
        .trigger('fancyselect.changed', [$(this)]);
});

// RESET SelectField, zurück auf Attribut Namen
window.resetSelectField = function (optionField) {
    let selectFieldWrapper = optionField.parents('.JS_fancySelectWrapper');
    let selectField = selectFieldWrapper.find('.JS_fancySelectField');
    let selectFieldName = selectFieldWrapper.find('.JS_fieldHint').text();
    let optionsWrapper = selectFieldWrapper.find('.JS_fancyOptionsWrapper');

    // Reset Select Field Name to default + reset Icon
    selectField
        .css({ 'border-color': '#b5b5b5' })
        .html(selectFieldName + '<i class="icon-down-dir" style="color: #123645;"></i>');

    optionsWrapper.addClass('d-none');

    hideSelectFieldName(optionField);
    resetAllOptionFields(optionField);
};

function resetAllOptionFields(optionField) {
    let allOptionFields = optionField.parents('.JS_fancyOptionsWrapper').find('.JS_fancyOptionField');
    allOptionFields.each(function (e) {
        $(this)
            .css({ color: '#333' })
            .removeClass('active')
            .find('.JS_fancyOptionField_icon')
            .attr('src', '/images/icons/check-box.svg');
    });
}

function fillSelectField(optionField) {
    optionField
        .parents('.JS_fancySelectWrapper')
        .find('.fancySelectField')
        .css({ 'border-color': '#123645' })
        .html(
            optionField.text() +
                '<i class="icon-brand_check_full fc-brand_green_hover"></i>' +
                '<i class="icon-down-dir"></i> '
        );
}

/* Show Feldnamen am oberen Rand des Feldes */
function showSelectFieldName(optionField) {
    optionField
        .parents('.JS_fancySelectWrapper')
        .find('.JS_fieldHint')
        .fadeIn()
        .parents('.JS_fancySelectWrapper')
        .css('margin-top', '-24px');
}

function activeOptionFieldAction(optionField) {
    optionField
        .addClass('active')
        .css({ color: '#123645' })
        .find('.JS_fancyOptionField_icon')
        .attr('src', '/images/icons/check-box-checked.svg');
}

/* Hide Feldnamen */
function hideSelectFieldName(optionField) {
    optionField
        .parents('.JS_fancySelectWrapper')
        .find('.JS_fieldHint')
        .hide()
        .parents('.JS_fancySelectWrapper')
        .css({ 'margin-top': '0' });
}

import BaseApiClient from '@/services/api/BaseApiClient';
import { transformToUser } from '@/models/account/User';
export default class UserApiClient extends BaseApiClient {
    handleResponse(response) {
        return transformToUser(response.data);
    }
    handleWishlistApiReturn(response) {
        return response.data;
    }
    toggleNewsletter(enabled) {
        return this.patch('/api/customer/toggleNewsletter', {
            newsletter: enabled,
        }).then(this.handleResponse);
    }
    pullUser() {
        return this.get('/api/account/info').then(this.handleResponse);
    }
    registerUser(registerData) {
        const requestData = {
            customers_email_address: registerData.email,
            customers_firstname: registerData.firstName,
            customers_lastname: registerData.lastName,
            password: registerData.password,
            password_validate: registerData.confirmedPassword,
        };
        return this.post('/auth/register', requestData).then(this.handleResponse);
    }
    registerUserFromBlueOrder(orderId, password, confirmedPassword) {
        const requestData = { orderId, password, confirmedPassword };
        return this.put('/auth/registerFromPurchase', requestData).then(this.handleResponse);
    }
    getWishlistProductIds() {
        return this.get('api/wishlist').then(this.handleWishlistApiReturn);
    }
    addProductToWishlist(id) {
        return this.put('api/wishlist/' + id).then(this.handleWishlistApiReturn);
    }
    removeProductFromWishlist(id) {
        return this.delete('api/wishlist/' + id).then(this.handleWishlistApiReturn);
    }
}

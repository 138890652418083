import { object as validateObject, string as validateString } from 'yup';
const messages = {
    simpleRequired: 'Du hast da was vergessen',
    notRight: 'Das sieht nicht ganz richtig aus...',
};
export const stringRequiredRule = validateString().required(messages.simpleRequired);
export const ibanRequiredRule = stringRequiredRule
    .clone()
    .min(15, 'Die IBAN ist zu kurz.')
    .max(34, 'Die IBAN ist zu lang.');
export const bankNameRequiredRule = stringRequiredRule
    .clone()
    .min(2, 'Der Bankname ist zu kurz.')
    .max(80, 'Die Bankname ist zu lang.');
export const accountHolderRequiredRule = stringRequiredRule
    .clone()
    .min(2, 'Der Name ist zu kurz.')
    .max(80, 'Der Name ist zu lang.');
// Email
export const emailRule = validateString().email('Bitte gib eine gültige E-Mail Adresse ein.');
export const emailRequiredRule = emailRule.clone().required(messages.simpleRequired);
const regexCompanyName = /^(?!.*\b(GmbH|AG|Co\.|OHG|KG|UG|Gesellschaft)\b).*$/;
// User
export const firstNameRule = validateString()
    .min(2, 'Sicher ?')
    .matches(regexCompanyName, 'Bitte Firmennamen im entsprechenden Feld eintragen.');
export const firstNameRequiredRule = firstNameRule.clone().required(messages.simpleRequired);
export const lastNameRule = validateString()
    .min(2, 'Sicher ?')
    .matches(regexCompanyName, 'Bitte Firmennamen im entsprechenden Feld eintragen.');
export const lastNameRequiredRule = lastNameRule.clone().required(messages.simpleRequired);
export const passwordRule = validateString()
    .min(8, 'Min. 8 Zeichen lang.')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'Das Passwort muss min. 1 Großbuchstaben, 1 Kleinbuchstaben und eine Zahl enthalten.')
    .required(messages.simpleRequired);
// Address
export const streetNameRule = validateString().min(2, messages.notRight);
export const streetNameRequiredRule = streetNameRule.clone().required('Bitte gib deine Straße ein.');
export const streetNumberRule = validateString().min(1, messages.notRight);
export const streetNumberRequiredRule = streetNumberRule
    .clone()
    .required('Bitte gib deine Hausnummer ein.');
export const postcodeRule = validateString().matches(/^(0[1-9]|[1-9]\d)\d{3}$/, messages.notRight);
export const postCodeRequiredRule = postcodeRule.clone().required('Bitte gib deine Postleitzahl ein.');
export const cityRule = validateString().min(2, messages.notRight).max(64, messages.notRight);
export const cityRequiredRule = cityRule.clone().required('Bitte gib deinen Wohnort ein.');
export const companyRule = validateString().nullable().optional().min(2, messages.notRight);
export const searchRule = validateString().matches(/^(.{2,}|)$/, messages.notRight);
export const addressRule = validateObject({
    first_name: firstNameRequiredRule,
    last_name: lastNameRequiredRule,
    street_name: streetNameRequiredRule,
    street_number: streetNumberRequiredRule,
    postcode: postCodeRequiredRule,
    city: cityRequiredRule,
    company: companyRule,
});

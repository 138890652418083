import { transformShippingOption, } from '@/models/red/ShippingOption';
export function transformToCart(data) {
    const cart = Object.assign({}, data);
    if (cart.shipping_option !== null) {
        cart.shipping_option = transformShippingOption(cart.shipping_option);
    }
    cart.products.forEach(function (product, index, list) {
        list[index] = Object.assign({}, product);
    });
    return cart;
}

import eventBus from '@/services/EventBus';

window.showRedNoPriceFoundInfo = (message) => {

    eventBus.emit('openInfoPopup', {
        icon: 'error',
        headline: 'Oh No!',
        message: message,
        timer: 3000
    })
};

window.showGenericInfo = (message) => {

    eventBus.emit('openInfoPopup', {
        icon: 'info',
        headline: 'Info',
        message: message,
        timer: 3000
    })
};

window.showGenericFail = (message) => {

    eventBus.emit('openInfoPopup', {
        icon: 'error',
        headline: 'Ein Fehler ist aufgetreten',
        message: message,
        timer: 3000
    })
};

window.showSwalHtmlInfo = (htmlMessage) => {

    eventBus.emit('openInfoPopup', {
        icon: 'info',
        headline: 'Info',
        message: htmlMessage,
        timer: 3000
    })
};

window.showSuccessMessage = (message) => {

    eventBus.emit('openInfoPopup', {
        icon: 'success',
        headline: 'Info',
        message: message,
        timer: 3000
    })
};

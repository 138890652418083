export const globalMutations = {
    SET_SERVER_MOBILE_FLAG(state, payload) {
        state.serverDetectMobile = payload;
    },
    SET_CAN_HANDLE_WEBP(state, payload) {
        state.clientCanHandleWebp = payload;
    },
    SET_LOCATIONS(state, payload) {
        state.locations = payload;
    },
    SET_REGION(state, payload) {
        state.currentRegion = payload;
    },
    INCREASE_PENDING_REQUEST_COUNT(state) {
        state.numberOfAjaxCallPending++;
    },
    DECREASE_PENDING_REQUEST_COUNT(state) {
        state.numberOfAjaxCallPending--;
    },
};

import { locationsApiClient } from '@/services/api';
export const globalActions = {
    LOAD_LOCATIONS(store, payload) {
        return new Promise(function () {
            var _a, _b, _c;
            return locationsApiClient
                .queryLocations((_a = payload === null || payload === void 0 ? void 0 : payload.opened_filter) !== null && _a !== void 0 ? _a : false, (_b = payload === null || payload === void 0 ? void 0 : payload.region_id) !== null && _b !== void 0 ? _b : null, (_c = payload === null || payload === void 0 ? void 0 : payload.can_do_local_store_payout) !== null && _c !== void 0 ? _c : null)
                .then((response) => store.commit('SET_LOCATIONS', response));
        });
    },
    SEARCH_LOCATIONS(store, payload) {
        return new Promise(function () {
            return locationsApiClient
                .searchLocationsByString(payload.search_string, payload.opened_filter, payload.region_id)
                .then((response) => store.commit('SET_LOCATIONS', response));
        });
    },
    GET_LOCATIONS_BY_GEOPOS(store, payload) {
        return new Promise(function () {
            return locationsApiClient
                .searchLocationsFromPos(payload.longitude, payload.latitude, payload.opened_filter, payload.region_id, payload.max_results, payload.with_opening_times, payload.with_categories, payload.localStorePayoutOnly)
                .then((response) => store.commit('SET_LOCATIONS', response));
        });
    },
    LOAD_REGION(store, payload) {
        return new Promise(function () {
            return locationsApiClient.queryRegion(payload).then((response) => store.commit('SET_REGION', response));
        });
    },
};

import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("span", {
        onClick: _cache[0] || (_cache[0] =
            //@ts-ignore
            (...args) => (_ctx.openPopUp && _ctx.openPopUp(...args))),
        style: { "cursor": "pointer" }
    }, [
        _renderSlot(_ctx.$slots, "default")
    ]));
}

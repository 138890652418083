import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["title", "href"];
const _hoisted_2 = { class: "fc-brand_deep_blue" };
const _hoisted_3 = { class: "fc-brand_deep_blue" };
const _hoisted_4 = {
    key: 0,
    class: "fc-brand_deep_blue"
};
const _hoisted_5 = { class: "fc-brand_deep_blue" };
const _hoisted_6 = { class: "fc-brand_deep_blue" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = {
    key: 2,
    class: "d-flex align-items-center justify-content-center w-100 text-center"
};
const _hoisted_9 = { class: "fc-brand_deep_blue" };
const _hoisted_10 = { class: "fc-brand_deep_blue" };
const _hoisted_11 = {
    key: 0,
    class: "fc-brand_deep_blue"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.slide.link && !_ctx.slide.linkIsEvent)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            title: _ctx.slide.text,
            class: "d-flex align-items-center justify-content-center w-100 text-center fc-brand_deep_blue text-underline",
            href: _ctx.slide.link
        }, [
            _createElementVNode("span", _hoisted_2, [
                _createElementVNode("i", {
                    class: _normalizeClass(["mr-1 font6 fc-brand_deep_blue", _ctx.slide.icon])
                }, null, 2)
            ]),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.slide.text), 1),
            (_ctx.slide.prependIcon)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _createElementVNode("i", {
                        class: _normalizeClass(["mr-1 font6 fc-brand_deep_blue", _ctx.slide.prependIcon])
                    }, null, 2)
                ]))
                : _createCommentVNode("", true)
        ], 8, _hoisted_1))
        : (_ctx.slide.link && _ctx.slide.linkIsEvent)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "d-flex align-items-center justify-content-center w-100 text-center pointerhover",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.runClick && _ctx.runClick(...args)))
            }, [
                _createElementVNode("span", _hoisted_5, [
                    _createElementVNode("i", {
                        class: _normalizeClass(["mr-1 font6 fc-brand_deep_blue", _ctx.slide.icon])
                    }, null, 2)
                ]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.slide.text), 1),
                (_ctx.slide.prependIcon)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                        _createElementVNode("i", {
                            class: _normalizeClass(["font4 fc-brand_deep_blue", _ctx.slide.prependIcon]),
                            style: { "line-height": "0" }
                        }, null, 2)
                    ]))
                    : _createCommentVNode("", true)
            ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                    _createElementVNode("i", {
                        class: _normalizeClass(["mr-1 font6 fc-brand_deep_blue", _ctx.slide.icon])
                    }, null, 2)
                ]),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.slide.text), 1),
                (_ctx.slide.prependIcon)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                        _createElementVNode("i", {
                            class: _normalizeClass(["mr-1 font6 fc-brand_deep_blue", _ctx.slide.prependIcon])
                        }, null, 2)
                    ]))
                    : _createCommentVNode("", true)
            ]));
}

import BaseApiClient from '@/services/api/BaseApiClient';
export default class ProductApiClient extends BaseApiClient {
    getPrice(evaluation) {
        return this.get('/api/red/price', {
            params: {
                product_id: evaluation.product_id,
                condition: evaluation.condition,
                ovp: evaluation.ovp ? 1 : 0,
                full_scope_of_delivery: evaluation.full_scope_of_delivery ? 1 : 0,
            },
        }).then((response) => (response.status < 299 ? response.data : Promise.reject(response.data)));
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent } from 'vue';
import sleep from '@/helpers/sleep';
export default defineComponent({
    name: 'faq-entry',
    props: {
        question: String,
        answer: String,
        image: String,
        anker: String,
        offset: Number,
    },
    data() {
        return {
            expanded: false,
        };
    },
    methods: {
        toggleExpansion() {
            this.expanded = !this.expanded;
        },
        checkUrlForAnker() {
            return __awaiter(this, void 0, void 0, function* () {
                const anker = window.location.hash;
                if (anker === '#' + this.anker) {
                    this.scrollMeTo();
                    this.expanded = true;
                    this.scrollMeTo(this.offset);
                }
            });
        },
        scrollMeTo(offset = 0) {
            const top = this.$el.getBoundingClientRect().top;
            window.scrollTo({ top: top - offset, behavior: 'smooth' });
        },
    },
    mounted() {
        this.$nextTick(function () {
            return __awaiter(this, void 0, void 0, function* () {
                // wait a 0.2 second for images and other dom-shifting elements to do their thing
                yield sleep(200);
                this.checkUrlForAnker();
            });
        });
    },
});

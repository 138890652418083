/**
 * Ein Button zum Öffnen des Popups benötigt zwei Klassennamen. 1. Einen Namen zum Ansprechen 2. JS_header_toggle_interactive :
 * [ JS_xxxxxxxxxxxxx JS_header_toggle_interactive ]
 * In der header.js werden die Klassennamen zugewiesen
 * */
import axios from '@/services/api/axios';
export class PopupManager {
    showPopup(name) {
        this.hidePopup();
        this.renderGrayOverlay();
        const element = document.querySelector('.JS_' + name + 'Wrapper');
        if (null === element) {
            this.hideGrayOverlay();
            return;
        }
        element.classList.remove('hidden');
        element.classList.add('active', 'fade-in');
    }
    hidePopup() {
        this.hideGrayOverlay();
        const element = document === null || document === void 0 ? void 0 : document.querySelector('.JS_ContentWrapper.active');
        if (null === element) {
            return;
        }
        element.classList.add('fade-out');
        setTimeout(() => {
            element.classList.add('hidden');
            element.classList.remove('active', 'fade-in', 'fade-out');
        }, 200);
    }
    renderGrayOverlay() {
        var _a, _b;
        (_b = (_a = document === null || document === void 0 ? void 0 : document.getElementById('grayish_overlay')) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.remove('hidden');
    }
    hideGrayOverlay() {
        var _a, _b;
        (_b = (_a = document === null || document === void 0 ? void 0 : document.getElementById('grayish_overlay')) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.add('hidden');
    }
    // Header-menus logic. see old header.js
    openActiveContentWrapper(activeButton) {
        // Neues Fenster öffnen:
        // Die Fenster bekommen den Namen "JS_....Wrapper". Hier in der header.js nur '....' eintragen. Also "JS_" und "Wrapper" weglassen, siehe auch "PopupManager.ts !!!
        switch (true) {
            case activeButton.classList.contains('JS_blueCartButton'):
                this.showPopup('cart');
                break;
            case activeButton.classList.contains('JS_blueWishlistButton'):
                // Öffne Wishlist Box
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.fillWishlist();
                this.showPopup('wishlist');
                break;
            case activeButton.classList.contains('JS_redShippingBoxButton'):
                // Öffne RED Shipping box
                axios.get('/verkaufen/versandbox').then((response) => {
                    const salesBox = document.querySelector('.JS_shippingBox_products');
                    if (salesBox === null) {
                        return;
                    }
                    salesBox.innerHTML = response.data;
                    this.showPopup('shippingBox');
                });
                break;
            default:
                this.hidePopup();
                break;
        }
    }
}
const popupManager = new PopupManager();
export default popupManager;

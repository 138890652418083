export const accountGetters = {
    isLogin: (state) => state.currentUser !== null,
    firstName: (state) => { var _a, _b; return (_b = (_a = state.currentUser) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : ''; },
    lastName: (state) => { var _a, _b; return (_b = (_a = state.currentUser) === null || _a === void 0 ? void 0 : _a.lastName) !== null && _b !== void 0 ? _b : ''; },
    fullName: (state) => { var _a, _b, _c, _d; return (((_b = (_a = state.currentUser) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '') + ' ' + ((_d = (_c = state.currentUser) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : '')).trim(); },
    email: (state) => { var _a, _b; return (_b = (_a = state.currentUser) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : ''; },
    birthday: (state) => { var _a, _b; return (_b = (_a = state.currentUser) === null || _a === void 0 ? void 0 : _a.birthday) !== null && _b !== void 0 ? _b : null; },
    hasNewsletter: (state) => { var _a, _b; return (_b = (_a = state.currentUser) === null || _a === void 0 ? void 0 : _a.newsletter) !== null && _b !== void 0 ? _b : false; },
    wishlist: (state) => state.wishlist,
};

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c82ab8a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "menuContent" };
const _hoisted_2 = { class: "menuList container row font7 mb-0" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-12 px-0 pb-2" };
const _hoisted_5 = { class: "col-12 h-100" };
const _hoisted_6 = { class: "menuItem_List ml-1 pl-2 border-left" };
const _hoisted_7 = { class: "menuItem_List_MainEntry" };
const _hoisted_8 = ["href", "title"];
const _hoisted_9 = ["href", "title"];
const _hoisted_10 = {
    key: 0,
    class: "menuItem_List_ReadMore"
};
const _hoisted_11 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_sheet = _resolveComponent("v-sheet");
    const _component_v_menu = _resolveComponent("v-menu");
    return (_openBlock(), _createBlock(_component_v_menu, { activator: "#menu-activator" }, {
        default: _withCtx(() => [
            _createVNode(_component_v_sheet, {
                class: "p-3",
                style: { "overflow-x": "hidden" }
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("ul", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
                                return (_openBlock(), _createElementBlock("li", {
                                    key: index,
                                    class: "menuItem col-lg-3 col-md-4 col-6 py-3"
                                }, [
                                    _createElementVNode("div", _hoisted_3, [
                                        _createElementVNode("div", _hoisted_4, [
                                            _createElementVNode("i", {
                                                class: _normalizeClass(["font2", item.icon])
                                            }, null, 2)
                                        ]),
                                        _createElementVNode("div", _hoisted_5, [
                                            _createElementVNode("ul", _hoisted_6, [
                                                _createElementVNode("li", _hoisted_7, [
                                                    _createElementVNode("a", {
                                                        class: "strong text-underline",
                                                        href: item.link,
                                                        title: item.name
                                                    }, _toDisplayString(item.name), 9, _hoisted_8)
                                                ]),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterSubcategories(item), (subcategory, index) => {
                                                    return (_openBlock(), _createElementBlock("li", {
                                                        key: index,
                                                        class: "menuItem_List_SubEntry"
                                                    }, [
                                                        _createElementVNode("a", {
                                                            href: subcategory.link,
                                                            title: subcategory.name
                                                        }, _toDisplayString(subcategory.name), 9, _hoisted_9)
                                                    ]));
                                                }), 128)),
                                                (item.subcategories.length > 4)
                                                    ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                                                        _createElementVNode("span", {
                                                            onClick: _withModifiers(($event) => (_ctx.toggleShowAll(item.id)), ["stop"]),
                                                            class: "readMoreLink strong"
                                                        }, _toDisplayString(_ctx.showAllForCategory(item.id) ? 'weniger anzeigen' : 'mehr anzeigen'), 9, _hoisted_11)
                                                    ]))
                                                    : _createCommentVNode("", true)
                                            ])
                                        ])
                                    ])
                                ]));
                            }), 128))
                        ])
                    ])
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}

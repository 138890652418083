import { transformToCart } from '@/models/blue/Cart';
import BaseApiClient from '@/services/api/BaseApiClient';
export default class BlueCartApiClient extends BaseApiClient {
    handleResponse(response) {
        const cart_response = Object.assign({}, response.data);
        cart_response.cart = transformToCart(response.data.cart);
        return cart_response;
    }
    handleErrorResponse(error) {
        if (!error.response) {
            throw error;
        }
        const cart_error_response = Object.assign({}, error.response.data);
        if (error.response.data.cart) {
            cart_error_response.cart = transformToCart(error.response.data.cart);
        }
        throw cart_error_response;
    }
    getCart(lock_cart = false) {
        const params = {};
        if (lock_cart) {
            params.lock = true;
        }
        return this.get('/api/blue/cart', { params })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    addCoupon(code) {
        return this.patch('/api/voucher/blue', {
            voucher_code: code,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    removeCoupon() {
        return this.delete('/api/voucher/blue').then(this.handleResponse).catch(this.handleErrorResponse);
    }
    addProduct(product_id, quantity) {
        return this.patch('/api/blue/addProduct', {
            product_id,
            quantity,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    removeProduct(product_id) {
        return this.delete('/api/blue/removeProduct/' + product_id)
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    changeProductQuantity(product_id, quantity) {
        return this.patch('/api/blue/changeProductQuantity', {
            product_id,
            quantity,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setShippingOption(shipping_option_id) {
        return this.post('/api/blue/setShippingOption', {
            shipping_option_id,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setPaymentType(payment_type_id) {
        return this.post('/api/blue/setPaymentType', {
            payment_type_id,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setAddress(identifier, address, storeAsDefault) {
        return this.patch('/api/blue/setAddress', {
            address_type: identifier,
            address: address,
            store_as_default: storeAsDefault,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    toggleBillingAddressUsage(use) {
        return this.patch('/api/blue/toggleUseBillingAddress', {
            use_billing_address: use,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setEmail(email, forRegistration) {
        return this.patch('/api/blue/setEmail', { email, forRegistration })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setRegisterNewsletter(register_newsletter) {
        return this.patch('/api/blue/registerNewsletter', {
            register_newsletter,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
}

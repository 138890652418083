import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2d26b5ff"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "contentWrapper" };
const _hoisted_3 = { class: "h1-responsive mt-md-0 mt-2" };
const _hoisted_4 = {
    key: 0,
    class: "font5-responsive"
};
const _hoisted_5 = {
    key: 1,
    class: "font5-responsive"
};
const _hoisted_6 = {
    key: 0,
    class: "col-12 col-md-5 m-0 p-sm-4 p-0 d-flex align-items-center justify-content-center"
};
const _hoisted_7 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            class: _normalizeClass(["col-12 d-flex align-items-center py-4", _ctx.imageUrl ? 'col-md-7' : 'pt-md-9 pt-3 pb-md-9 pb-3'])
        }, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.headline), 1),
                (_ctx.optionalText)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.optionalText), 1))
                    : _createCommentVNode("", true),
                (_ctx.hasOptionalTextSlot)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                        _renderSlot(_ctx.$slots, "optional_text", {}, undefined, true)
                    ]))
                    : _createCommentVNode("", true)
            ])
        ], 2),
        (_ctx.imageUrl)
            ? (_openBlock(), _createElementBlock("figure", _hoisted_6, [
                _createElementVNode("img", {
                    src: _ctx.optimizeImage(_ctx.imageUrl, 250),
                    alt: _ctx.headline,
                    class: "imageStyling d-sm-block d-none"
                }, null, 8, _hoisted_7)
            ]))
            : _createCommentVNode("", true)
    ]));
}

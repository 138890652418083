import { accountGetters } from './getters';
import { accountMutations } from './mutations';
import { accountActions } from './actions';
import { accountState } from './state';
export const accountModule = {
    actions: accountActions,
    getters: accountGetters,
    mutations: accountMutations,
    state: accountState,
    namespaced: true,
};

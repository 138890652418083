import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-56fe1eee"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "h6-responsive text-left m-0" };
const _hoisted_2 = {
    key: 0,
    class: "row entryAnswer font6-responsive pl-3 pr-3 pb-4 text-left"
};
const _hoisted_3 = { class: "col-sm-5 mb-3 text-center" };
const _hoisted_4 = ["src", "title"];
const _hoisted_5 = { class: "col-sm-7" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["entry", { 'border-bottom': _ctx.expanded }])
    }, [
        _createElementVNode("div", {
            class: _normalizeClass(["header px-0 py-4", { 'border-bottom': !_ctx.expanded }])
        }, [
            _createElementVNode("p", _hoisted_1, [
                _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.toggleExpanded && _ctx.toggleExpanded(...args))),
                    title: "Zeige die Erklärung",
                    class: "faqCard-Question pl-0 pr-1 d-flex justify-content-between align-items-start strong pointerhover fc-brand_deep_blue",
                    role: "button"
                }, [
                    _createTextVNode(_toDisplayString(_ctx.question) + " ", 1),
                    _createElementVNode("i", {
                        class: _normalizeClass(["float-right ml-4 fc-brand_green_hover", { 'icon-brand_plus': !_ctx.expanded, 'icon-brand_minus': _ctx.expanded }])
                    }, null, 2)
                ])
            ])
        ], 2),
        _createVNode(_Transition, {
            name: "collapse",
            duration: 280
        }, {
            default: _withCtx(() => [
                (_ctx.expanded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("img", {
                                src: _ctx.image,
                                title: _ctx.question,
                                class: _normalizeClass({ imageOpacity: _ctx.expanded }),
                                style: { "width": "100%", "object-fit": "contain" }
                            }, null, 10, _hoisted_4)
                        ]),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.answer), 1)
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        })
    ], 2));
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_header_advantage_entry = _resolveComponent("header-advantage-entry");
    const _component_v_carousel_item = _resolveComponent("v-carousel-item");
    const _component_v_carousel = _resolveComponent("v-carousel");
    return (_openBlock(), _createBlock(_component_v_carousel, {
        continuous: true,
        cycle: true,
        "show-arrows": false,
        "hide-delimiters": false,
        "hide-delimiter-background": "",
        "delimiter-icon": "icon-circle",
        height: "244",
        class: "progressButtonsChanged"
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header_slider_texts, (slide, index) => {
                return (_openBlock(), _createBlock(_component_v_carousel_item, { key: index }, {
                    default: _withCtx(() => [
                        _createVNode(_component_header_advantage_entry, {
                            slide: slide,
                            class: "font7-responsive pt-1"
                        }, null, 8, ["slide"])
                    ]),
                    _: 2
                }, 1024));
            }), 128))
        ]),
        _: 1
    }));
}

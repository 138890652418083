var swal_stack = [];
var SweetAlert;

//override swal to add a trigger
(function () {
    // Store a reference to the original remove method.
    var original = swal;

    SweetAlert = swal;

    // Define overriding method.
    window.swal = function (data) {
        let ignore_trigger = false;

        //check for second argument
        if (arguments[1]) {
            ignore_trigger = arguments[1];
        }

        let opened_state = SweetAlert.getState().isOpen;

        if (!ignore_trigger && opened_state) {
            $(window).trigger('swal_before-unload');
        }

        return original.apply(this, [arguments[0]]);
    };
})();

$(window).on('swal_closing', function (e) {
    resetSwalStack();

    // Nach öffnen der Sitebar wurde der Body auf "height: 100vh" gesetzt damit der Hintergrund nicht scrolled, sobald im Modal gescrolled wird.
    // Nach schließen des Modals, hier den body wieder auf normale body-height zurücksetzen:
    $('.JS_body').css({overflow: 'auto', height: 'auto'});

});

$(window).on('swal_before-unload', function (e) {
    //read current content and push to stack
    var html = $('.swal-modal:visible .swal-content').html();
    let classes = $('.swal-modal:visible').attr('class');
    if (typeof html != 'undefined') {
        classes = classes.replace('swal-modal ', '');
        swal_stack.push({
            html:  html,
            class: classes,
        });
    }
});

function showPreviousModal() {
    let prev = swal_stack.pop();
    if (typeof prev != 'undefined') {
        renderAsModal(prev.html, prev.class, true);
    }
}

function resetSwalStack() {
    swal_stack = [];
}

$(window).on('swal_opened', function () {
    // HAUPTSEITEN HÖHE SPERREN um Scrollen in der Sitebar ohne Hintergrundscrolling zu ermöglichen !!
    $('.JS_body').css({overflow: 'hidden', height: '100vh'});

    if (!$('.swal-modal').find('.dark_background').length) {
        $('.swal-modal').prepend(
            '<i id="close_swal_button" class="greyish_text icon-brand_close position-absolute pointerhover" style="\n' +
            '    right: 1rem;\n' +
            '    top: 0.7rem;\n' +
            '    z-index: 10000;\n' +
            '    width: 1.5rem;\n' +
            '    height: 1.5rem;\n' +
            '    display: block;\n' +
            '    font-size: 1.3rem;\n' +
            '"></i>',
        );

        if (swal_stack.length) {
            $('.swal-modal').prepend(
                '<i id="prev_swal_button" class="greyish_text icon-arrow-prev position-absolute pointerhover" style="\n' +
                '    right: 4rem;\n' +
                '    top: 1.09rem;\n' +
                '    z-index: 10000;\n' +
                '    width: 1.5rem;\n' +
                '    height: 1.5rem;\n' +
                '    display: block;\n' +
                '    font-size: 0.9rem;\n' +
                '"></i>',
            );
        }
    }
});

$(document).on('click', '#close_swal_button', function () {
    resetSwalStack();
    SweetAlert.close();
});

$(document).on('click', '#prev_swal_button', function () {
    showPreviousModal();
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bluePaymentMethods, (method, index) => {
        return (_openBlock(), _createElementBlock("a", {
            key: index,
            href: "/hilfe/zahlung_versand",
            class: "mb-3 pr-md-3 pr-0 px-md-0 px-2",
            title: "Zahlungsmethoden anzeigen"
        }, [
            _createElementVNode("img", {
                src: method.image,
                alt: method.question,
                width: "98",
                height: "40"
            }, null, 8, _hoisted_1)
        ]));
    }), 128));
}

const myswooopTheme = {
    dark: false,
    colors: {
        primary: '#123645',
        'on-primary': '#ffffff',
        background: '#f2fffd',
        'on-background': '#00201e',
        surface: '#fff',
        'on-surface': '#062734',
        outline: '#70787d',
        secondary: '#6c757d',
        'on-secondary': '#00201e',
        error: '#ff5e31',
        'on-error': '#ffffff',
        info: '#089bd8',
        'on-info': '#fff',
        success: '#a4ff78',
        'on-success': '#123645',
        warning: '#ff9a6a',
        'on-warning': '#000',
    },
    variables: {
        'medium-emphasis-opacity': 0.8,
        'high-emphasis-opacity': 0.9,
        'theme-overlay-multiplier': 1,
    },
};
export default myswooopTheme;

import BaseApiClient from '@/services/api/BaseApiClient';
export default class LocationsApiClient extends BaseApiClient {
    handleLocationsResponse(response) {
        return [...response.data];
    }
    handleRegionResponse(response) {
        return Object.assign({}, response.data);
    }
    handleLocationResponse(response) {
        return Object.assign({}, response.data);
    }
    handleErrorResponse() {
        return null;
    }
    searchLocationsByString(search_string, opened_filter, region_id, max_results = null, with_opening_times = false, with_categories = false, localStoreOnly = false) {
        return this.searchLocations({ searchstring: search_string, localStoreOnly }, opened_filter, region_id, max_results, with_opening_times, with_categories);
    }
    searchLocationsFromPos(longitude, latitude, opened_filter, region_id, max_results = null, with_opening_times = false, with_categories = false, localStoreOnly = null) {
        return this.searchLocations({ longitude, latitude, localStoreOnly }, opened_filter, region_id, max_results, with_opening_times, with_categories);
    }
    searchLocations(baseParams, opened_filter, region_id, max_results = null, with_opening_times = false, with_categories = false) {
        const embedded = [];
        if (with_opening_times) {
            embedded.push('opening_times');
        }
        if (with_categories) {
            embedded.push('active_red_categories');
        }
        const params = Object.assign(Object.assign({}, baseParams), { is_open: opened_filter, region_id,
            max_results, embedded: embedded.length > 0 ? embedded.join(',') : null });
        return this.get('/api/search/locations', { params })
            .then(this.handleLocationsResponse)
            .catch(this.handleErrorResponse);
    }
    queryLocations(opened_filter, region_id, can_do_local_store_payout) {
        const params = {
            is_open: opened_filter,
            region_id: region_id,
            can_do_local_store_payout: can_do_local_store_payout,
        };
        return this.get('/api/locations', { params })
            .then(this.handleLocationsResponse)
            .catch(this.handleErrorResponse);
    }
    queryLocation(id) {
        return this.get('/api/locations/' + id)
            .then(this.handleLocationResponse)
            .catch(this.handleErrorResponse);
    }
    queryLocationBySchubertId(id) {
        return this.get('/api/locations/bySchubertId/' + id)
            .then(this.handleLocationResponse)
            .catch(this.handleErrorResponse);
    }
    queryRegion(slug) {
        return this.get('/api/regions/' + slug)
            .then(this.handleRegionResponse)
            .catch(this.handleErrorResponse);
    }
}

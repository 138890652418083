import { defineComponent } from 'vue';
import optimizeImage from '@/services/ImageOptimize';
export default defineComponent({
    name: 'standard-static-header',
    methods: { optimizeImage },
    props: {
        headline: {
            type: String,
            required: true,
        },
        optionalText: {
            type: String,
            required: false,
            default: '',
        },
        imageUrl: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        hasOptionalTextSlot() {
            return this.$slots['optional_text'] !== undefined;
        },
    },
});

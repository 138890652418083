$(() => {

    // Sticky Header Logik ********************
    const fixedHeaderPart = $('.JS_fixedHeaderPart');
    const fixedHeaderPart_Spacer = $('.JS_fixedHeaderPart_spacer');
    const appHeight = $('#app').height();
    const windowHeight = $(window).height();

    // revert top offset that is added to reduce cls
    fixedHeaderPart.css('top', 0);

    document.onscroll = (event) => {

        /* Der App Div muss ein bisschen größer sein als das Fenster, sonst klappt die NAVI nicht ein, weil sinnlos */
        if (appHeight - windowHeight < 16) {
            return;
        }

        if ($(document).scrollTop() > $('.JS_aboveStickyContent').height()) {
            fixedHeaderPart.css({position: 'fixed', 'border-bottom': '1px solid #dee2e6'});

            // Spacer nimmt Höhe der Nav an, damit Seite nicht springt, sobald Sticky-Content position:fixed annimmt
            fixedHeaderPart_Spacer.css('height', `${fixedHeaderPart.height()}px`);
        } else {
            fixedHeaderPart.css({position: 'initial', border: '0'});
            fixedHeaderPart_Spacer.css('height', 0);
        }
    };


    // Klick auf Grayish Overlay schließt Drowdown Menüfenster (Account, Wishlist, Warenkorb, Versandbox)
    document.getElementById('grayish_overlay')?.addEventListener('click', function () {
        window.popupManager.hidePopup();
        window.hideLoadingAnimation();
    });

    // close-icon should close modal
    $('.popUpWrapperMobile .icon-brand_close, .popUpWrapper .icon-brand_close, .popUpWrapperBig .icon-brand_close').on('click', () => {
        window.popupManager.hidePopup();
    });

    // Account Button
    $('.JS_header_toggle_interactive').on('click', function (e) {
        window.popupManager.openActiveContentWrapper(e.target);
    });

    // Lupe, Suche starten
    $('.JS_searchButton').on('click', () => {
        $('.JS_searchResultWrapper').fadeToggle();
    });

    window.dispatchEvent(new CustomEvent('scroll'));
    document.dispatchEvent(new CustomEvent('scroll'));
});

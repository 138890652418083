import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
export default defineComponent({
    name: 'PaymentMethodsInfos',
    methods: Object.assign({}, mapActions('blue', { loadBluePayments: 'LOAD_BLUE_PAYMENTS' })),
    computed: Object.assign({}, mapGetters('blue', {
        bluePaymentMethods: 'bluePaymentMethods',
    })),
    mounted() {
        if (!this.bluePaymentMethods.length) {
            this.loadBluePayments();
        }
    },
});

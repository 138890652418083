import BlueCartApiClient from '@/services/api/BlueCartApiClient';
import CouponApiClient from '@/services/api/CouponApiClient';
import UserApiClient from '@/services/api/UserApiClient';
import ProductApiClient from '@/services/api/red/ProductApiClient';
import CartApiClient from '@/services/api/red/CartApiClient';
import httpClient from '@/services/api/axios';
import LocationsApiClient from '@/services/api/locations/LocationsApiClient';
import FilterApiClient from '@/services/api/blue/FilterApiClient';
import BlueProductsApiClient from '@/services/api/blue/BlueProductsApiClient';
export const blueCartApiClient = new BlueCartApiClient(httpClient);
export const couponApiClient = new CouponApiClient(httpClient);
export const userApiClient = new UserApiClient(httpClient);
export const redProductApiClient = new ProductApiClient(httpClient);
export const redCartApiClient = new CartApiClient(httpClient);
export const locationsApiClient = new LocationsApiClient(httpClient);
export const blueProductsApiClient = new BlueProductsApiClient(httpClient);
export const filterApiClient = new FilterApiClient(httpClient);

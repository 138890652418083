import { couponApiClient, redCartApiClient, redProductApiClient } from '@/services/api';
import { CouponType } from '@/models/account/Coupon';
import { transformToShippingOptions } from '@/models/red/ShippingOption';
import { transformToPayoutOptions } from '@/models/red/PayoutOptions';
import { toApiString } from '@/services/formattter/Date';
function handleCartApiRequest(apiCall, store) {
    return apiCall
        .then(function (cartBag) {
        store.commit('SET_VALID_CART', cartBag.valid_for_checkout);
        store.commit('SET_CART', cartBag.cart);
        store.commit('SET_PAYOUT_OPTIONS', transformToPayoutOptions(cartBag.payout_options));
        store.commit('SET_SHIPPING_OPTIONS', transformToShippingOptions(cartBag.shipping_options));
    })
        .catch(function (error) {
        var _a;
        store.commit('SET_CART_ERRORS', error.errors);
        store.commit('SET_VALID_CART', (_a = error.valid_for_checkout) !== null && _a !== void 0 ? _a : false);
        if (error.cart) {
            store.commit('SET_CART', error.cart);
        }
        if (error.payout_options) {
            store.commit('SET_PAYOUT_OPTIONS', transformToPayoutOptions(error.payout_options));
        }
        if (error.shipping_options) {
            store.commit('SET_SHIPPING_OPTIONS', transformToShippingOptions(error.shipping_options));
        }
        throw error.errors;
    });
}
export const redActions = {
    ADD_TO_CART(store, payload) {
        return redCartApiClient
            .addProductToCart(payload.product_id, payload.condition, payload.ovp, payload.full_scope_of_delivery, payload.replacement_for)
            .then((response) => {
            store.commit('SET_CART', response.cart);
        });
    },
    LOAD_CART_BAG(store) {
        return handleCartApiRequest(redCartApiClient.getCartBag(), store);
    },
    REMOVE_FROM_CART(store, payload) {
        return redCartApiClient.removeProductFromCart(payload.product_uuid).then((response) => {
            return handleCartApiRequest(Promise.resolve(response), store).then(() => {
                return response;
            });
        });
    },
    FIND_PRODUCT_PRICE(store, payload) {
        return redProductApiClient.getPrice(payload).then((price_response) => price_response.price);
    },
    LOAD_COUPONS(store) {
        couponApiClient.getCoupons(CouponType.RED).then(function (coupons) {
            store.commit('SET_COUPONS', coupons);
        });
    },
    SET_COUPON_CODE(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(redCartApiClient.setCouponCode(payload), store);
    },
    REMOVE_COUPON_CODE(store) {
        return handleCartApiRequest(redCartApiClient.removeCoupon(), store);
    },
    SET_SELF_SENT_SHIPPING(store) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(redCartApiClient.setSelfSentShipping(), store);
    },
    SET_PICKUP_SHIPPING(store, payload) {
        store.commit('SET_VALID_CART', null);
        const pickupDate = payload.pickup_date ? toApiString(payload.pickup_date) : null;
        return handleCartApiRequest(redCartApiClient.setPickupShipping(pickupDate), store);
    },
    SET_ADDRESS(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(redCartApiClient.setAddress(payload), store);
    },
    SET_BANK_TRANSFER_PAYOUT(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(redCartApiClient.setBankTransferPayout(payload), store);
    },
    SET_PAYPAL_PAYOUT(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(redCartApiClient.setPayPalPayout(payload.email), store);
    },
    SET_LOCAL_STORE_PAYOUT(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(redCartApiClient.setLocalStorePayout(payload.target_location_id), store);
    },
    SET_REGISTER_NEWSLETTER(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(redCartApiClient.setRegisterNewsletter(payload), store);
    },
    GET_AVAILABLE_PICKUP_DATES(store) {
        redCartApiClient.getValidPickupDates().then(function (data) {
            store.commit('SET_PICKUP_DATES', data);
        });
    },
};

const elementHelper = {
    shakeElement(element) {
        element.classList.add('nope-shake');
        setTimeout(() => element.classList.remove('nope-shake'), 500);
    },
    resetInvalidCartBorder(element) {
        element.classList.remove('invalid-cart');
    },
    setInvalidCartBorder(element) {
        element.classList.add('invalid-cart');
    },
    scrollToTarget(target, offsetTop = 0) {
        var _a, _b;
        let mobileOffset = 0;
        const isMobile = (_b = (_a = document.getElementById('contentTemplate')) === null || _a === void 0 ? void 0 : _a.classList.contains('mobile')) !== null && _b !== void 0 ? _b : false;
        if (isMobile) {
            mobileOffset = 50;
        }
        let targetElement = target;
        if (typeof targetElement === 'string') {
            const foundTarget = document.querySelector(targetElement);
            if (!foundTarget) {
                return;
            }
            targetElement = foundTarget;
        }
        const header = document.getElementsByTagName('header')[0];
        const headerHeight = header.clientHeight;
        const scrollToPosition = targetElement.getBoundingClientRect().top - headerHeight + mobileOffset - offsetTop + window.scrollY;
        const html = document.querySelector('html');
        if (!html) {
            return;
        }
        html.style.scrollBehavior = 'smooth';
        html.scrollTo({ left: 0, top: scrollToPosition });
        html.style.scrollBehavior = 'inherit';
    },
};
export default elementHelper;

export const accountMutations = {
    SET_CURRENT_USER(state, user) {
        state.currentUser = user;
    },
    RESET_CURRENT_USER(state) {
        state.currentUser = null;
    },
    SET_WISHLIST_ITEMS(state, productIds) {
        state.wishlist = productIds;
    },
};

export default class DatalayerService {
    pushProductListing(products) {
        const impressions = [];
        products.forEach((product, index) => {
            impressions.push({
                id: product.cArtNr,
                name: product.name,
                price: product.currentPrice,
                category_lvl1_id: product.firstLevelCatId,
                model_id: product.modelId,
                product_uuid: product.productUuid,
                position: index + 1,
            });
        });
        this.push({
            ecommerce: {
                impressions: impressions,
            },
        });
    }
    pushProductView(product) {
        this.push({
            ecommerce: {
                detail: {
                    products: [
                        {
                            id: product.cArtNr,
                            name: product.name,
                            price: product.currentPrice,
                            category_lvl1_id: product.firstLevelCatId,
                            model_id: product.modelId,
                            product_uuid: product.productUuid,
                        },
                    ],
                },
            },
        });
    }
    pushCategoryPath(categoryPath, categoryDepth = 0) {
        this.push({
            category_path: this.reduceCategoryPath(categoryPath, categoryDepth),
        });
    }
    pushCategoryPathBlue(categoryPathBlue, categoryDepth = 0) {
        this.push({
            category_path_blue: this.reduceCategoryPath(categoryPathBlue, categoryDepth),
        });
    }
    push(data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (typeof window.dataLayer === 'undefined') {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.dataLayer.push(data);
    }
    reduceCategoryPath(categoryPath, categoryDepth = 0) {
        if (categoryDepth === 0) {
            return categoryPath;
        }
        const split = categoryPath.split('>').slice(0, categoryDepth);
        return split.join('>').trimEnd();
    }
}

import { transformToUser } from '@/models/account/User';
import { transformToCoupon } from '@/models/account/Coupon';
import { transformToCart as transformToRedCart } from '@/models/red/Cart';
import { transformToCart as transformToBlueCart } from '@/models/blue/Cart';
import { transformToCartItem } from '@/models/blue/CartItem';
const modelTransform = {
    transformToUser,
    transformToCoupon,
    transformToBlueCart,
    transformToCartItem,
    transformToRedCart,
};
export default modelTransform;

// Hier der initiale Wert:
export const redState = {
    cart: null,
    cart_errors: null,
    coupons: [],
    valid_for_checkout: null,
    payout_options: null,
    shipping_options: null,
    pickup_dates: [],
};

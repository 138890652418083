var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BaseApiClient from '@/services/api/BaseApiClient';
export default class BlueProductsApiClient extends BaseApiClient {
    handlePaginatedResponse(response) {
        return {
            products: response.data.data,
            total: response.data.meta.total,
            pageInfo: response.data.meta,
            category_depth: response.data.category_depth,
        };
    }
    handleSelectionResponse(response) {
        return response.data;
    }
    getProductByFilterSelection(modelId, selectedFilters) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.post(`/api/blue/product/fetchProductBySelection/${modelId}`, { selected: selectedFilters }).then(this.handleSelectionResponse);
        });
    }
    getDeals(page, perPage, dealSection, categoryId) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page: page, page_size: perPage };
            if (dealSection !== null && dealSection !== undefined) {
                params.deal_section = dealSection;
            }
            if (categoryId !== null && categoryId !== undefined) {
                params.category_id = categoryId;
            }
            return this.get('/kaufen/getDealsProducts', { params }).then(this.handlePaginatedResponse);
        });
    }
    getProducts(page, perPage, listingType, listingValue, sorting, priceRange, conditions, brands, dealsOnly = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                page: page,
                page_size: perPage,
                listingType: listingType,
                listingValue: listingValue,
                sorting: sorting,
                dealsOnly: dealsOnly,
            };
            if (priceRange !== null && priceRange !== undefined) {
                params.maxPrice = priceRange.end;
                params.minPrice = priceRange.start;
            }
            if (brands !== null && brands !== undefined) {
                params.brands = brands;
            }
            if (conditions !== null && conditions !== undefined) {
                params.conditions = conditions;
            }
            if (dealsOnly !== undefined) {
                params.dealsOnly = dealsOnly;
            }
            return this.get('/api/blue/product/list', { params }).then(this.handlePaginatedResponse);
        });
    }
    getSearchProducts(type, phrase) {
        const parameters = new URLSearchParams({
            type: type,
            phrase: phrase,
        });
        return this.get(`/api/search_autocomplete?${parameters.toString()}`).then((response) => {
            return response.data;
        });
    }
}

import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const httpClient = axios;
// Add a request interceptor
httpClient.interceptors.request.use((config) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.useStore().commit('global/INCREASE_PENDING_REQUEST_COUNT');
    return config;
}, (error) => Promise.reject(error));
// Add a response interceptor
httpClient.interceptors.response.use((response) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.useStore().commit('global/DECREASE_PENDING_REQUEST_COUNT');
    return response;
}, (error) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.useStore().commit('global/DECREASE_PENDING_REQUEST_COUNT');
    return Promise.reject(error);
});
httpClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export default httpClient;

import eventBus from '@/services/EventBus';
import authService from '@/services/auth/AuthService';
import popupManager from '@/services/Dom/PopupManager';
// REGISTER GLOBAL EVENT LISTENERS HERE
// BUS
eventBus.on('LoggedIn', () => {
    authService.loggedIn();
});
eventBus.on('SHOW_GARANTIE_POPUP', () => popupManager.showPopup('garantie'));
// DOM
document.addEventListener('DOMContentLoaded', () => {
    const elements = document.querySelectorAll('.needs_login');
    elements.forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            authService.needsLogin(event.target);
        });
    });
    const desktopMenuButton = document.querySelectorAll('.JS_accountButton');
    desktopMenuButton.forEach((menuTrigger) => {
        menuTrigger.addEventListener('click', () => {
            popupManager.showPopup('account');
        });
    });
});

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6574fcce"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "faqCard" };
const _hoisted_2 = {
    key: 0,
    class: "faqCard-Header px-0 py-4"
};
const _hoisted_3 = { class: "font6-responsive text-left" };
const _hoisted_4 = ["title"];
const _hoisted_5 = { key: 1 };
const _hoisted_6 = ["title"];
const _hoisted_7 = { class: "row mx-sm-0 no-gutters" };
const _hoisted_8 = {
    class: "col-6 py-sm-4 py-3 text-left strong d-flex align-items-center",
    style: {}
};
const _hoisted_9 = { class: "col-5 d-flex align-items-center justify-content-center justify-content-sm-end" };
const _hoisted_10 = ["src", "alt"];
const _hoisted_11 = { class: "col-1 d-flex align-items-center justify-content-end p-0 pr-1" };
const _hoisted_12 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.image)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h3", _hoisted_3, [
                    _createElementVNode("a", {
                        title: `Frage und Antwort - ${_ctx.question}`,
                        class: "faqCard-Question pl-0 pr-1 d-flex justify-content-between align-items-start strong pointerhover fc-brand_deep_blue",
                        role: "button",
                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.toggleExpansion()))
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.question) + " ", 1),
                        _createElementVNode("i", {
                            class: _normalizeClass([{ 'icon-brand_plus': !_ctx.expanded, 'icon-brand_minus': _ctx.expanded }, "float-right ml-4 fc-brand_green_hover"])
                        }, null, 2)
                    ], 8, _hoisted_4)
                ])
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("a", {
                    title: `Frage und Antwort - ${_ctx.question}`,
                    class: "faqCard-Question pointerhover w-100",
                    role: "button",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.toggleExpansion()))
                }, [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.question), 1),
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("img", {
                                src: _ctx.image,
                                style: { "max-width": "100%", "max-height": "100%", "object-fit": "contain" },
                                alt: _ctx.question,
                                width: "78",
                                height: "32"
                            }, null, 8, _hoisted_10)
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("i", {
                                class: _normalizeClass([{ 'icon-brand_plus': !_ctx.expanded, 'icon-brand_minus': _ctx.expanded }, "float-right fc-brand_green_hover"])
                            }, null, 2)
                        ])
                    ])
                ], 8, _hoisted_6)
            ])),
        _createVNode(_Transition, {
            name: "collapse",
            duration: 280
        }, {
            default: _withCtx(() => [
                (_ctx.expanded)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        innerHTML: _ctx.answer,
                        class: "faqCard-Answer font6-responsive pl-3 pr-3 pb-4 text-left"
                    }, null, 8, _hoisted_12))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        })
    ]));
}

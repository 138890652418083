import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { blueProductsApiClient } from '@/services/api';
export default defineComponent({
    name: 'search-bar',
    props: {
        blueOrRed: {
            required: true,
            type: String,
        },
    },
    computed: Object.assign(Object.assign({}, mapGetters('global', {
        isMobile: 'isMobile',
    })), { isBlueOrRed() {
            return this.blueOrRed == 'blue' || this.blueOrRed == 'red';
        },
        placeholderText() {
            if (this.blueOrRed == 'blue') {
                return 'Was möchtest Du kaufen?';
            }
            if (this.blueOrRed == 'red') {
                return 'Was möchtest Du verkaufen?';
            }
            else {
                return 'Suchbegriff eingeben';
            }
        } }),
    data() {
        return {
            itemsResponse: [],
        };
    },
    methods: {
        updateItems(phrase) {
            blueProductsApiClient.getSearchProducts(this.blueOrRed == 'blue' ? 'buy' : 'sell', phrase).then((items) => {
                this.itemsResponse = items;
            });
        },
        search(phrase) {
            var _a, _b;
            if (typeof phrase !== 'string') {
                console.error('invalid search phrase passed');
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!((_b = (_a = this.$refs) === null || _a === void 0 ? void 0 : _a.searchautocomplete) === null || _b === void 0 ? void 0 : _b.isFocused)) {
                return;
            }
            if (phrase.startsWith('/') || phrase.startsWith('http')) {
                window.location.href = phrase;
                return;
            }
            if (phrase.length < 3) {
                return;
            }
            this.updateItems(phrase);
        },
    },
});

import { transformToCoupon } from '@/models/account/Coupon';
import BaseApiClient from '@/services/api/BaseApiClient';
export default class CouponApiClient extends BaseApiClient {
    handleResponse(response) {
        const coupons = [];
        response.data.forEach((coupon, index) => {
            coupons[index] = transformToCoupon(coupon);
        });
        return coupons;
    }
    getCoupons(type = null) {
        const params = {};
        if (type !== null) {
            params.type = type;
        }
        return this.get('/api/account/vouchers', { params }).then(this.handleResponse);
    }
}

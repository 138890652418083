import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
export default defineComponent({
    name: 'blue-dropdown-menu',
    data() {
        return {
            menuItems: [],
            showData: [],
        };
    },
    computed: Object.assign({}, mapGetters('blue', ['isCartFilled'])),
    methods: {
        loadCatMenu() {
            axios
                .get('/api/blue/menu')
                .then((response) => {
                this.showData = [];
                this.menuItems = response.data;
            })
                .catch((error) => {
                console.log('Da ist was schief gelaufen, ERROR: ' + error);
            });
        },
        filterSubcategories(item) {
            if (this.showAllForCategory(item.id)) {
                return item.subcategories;
            }
            return item.subcategories.slice(0, 4);
        },
        toggleShowAll(id) {
            if (this.showAllForCategory(id)) {
                this.showData = this.showData.filter((element) => element !== id);
                return;
            }
            this.showData.push(id);
        },
        showAllForCategory(id) {
            return this.showData.find((element) => element === id) !== undefined;
        },
    },
    mounted() {
        this.loadCatMenu();
    },
});

import BaseApiClient from '@/services/api/BaseApiClient';
import { transformToCart } from '@/models/red/Cart';
import { transformToPayoutOptions } from '@/models/red/PayoutOptions';
import { transformToShippingOptions } from '@/models/red/ShippingOption';
export default class CartApiClient extends BaseApiClient {
    handleResponse(response) {
        return {
            cart: transformToCart(response.data.cart),
            valid_for_checkout: response.data.valid_for_checkout,
            payout_options: transformToPayoutOptions(response.data.payout_options),
            shipping_options: transformToShippingOptions(response.data.shipping_options),
            errors: null,
        };
    }
    handleErrorResponse(error) {
        if (!error.response) {
            throw error;
        }
        const cart_error_response = Object.assign({}, error.response.data);
        if (error.response.data.cart) {
            cart_error_response.cart = transformToCart(error.response.data.cart);
        }
        if (error.response.data.payout_options) {
            cart_error_response.payout_options = transformToPayoutOptions(error.response.data.payout_options);
        }
        if (error.response.data.shipping_options) {
            cart_error_response.shipping_options = transformToShippingOptions(error.response.data.shipping_options);
        }
        throw cart_error_response;
    }
    loadCartBag() {
        return this.get('/api/red/cartBag').then(this.handleResponse).catch(this.handleErrorResponse);
    }
    addProductToCart(product_id, condition, ovp, full_scope_of_delivery, replacement_for = null) {
        return this.put('/api/red/product', {
            product_id,
            condition,
            ovp,
            full_scope_of_delivery,
            replacement_for,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    removeProductFromCart(product_uuid) {
        return this.delete(`/api/red/product/${product_uuid}`)
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setSelfSentShipping() {
        return this.put('/api/red/shipment/selfSent')
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setPickupShipping(pickup_date) {
        return this.put('api/red/shipment/pickup', {
            pickup_date: pickup_date,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setAddress(address) {
        return this.put('/api/red/address', Object.assign({}, address))
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setBankTransferPayout(payload) {
        return this.put('/api/red/payout/bankTransfer', {
            iban: payload.iban,
            account_holder: payload.account_holder,
            bank: payload.bank,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setPayPalPayout(email) {
        return this.put('/api/red/payout/paypal', { email })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setLocalStorePayout(target_location_id) {
        return this.put('/api/red/payout/localStore', { target_location_id })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    setCouponCode(coupon_code) {
        return this.put('/api/red/coupon', {
            coupon_code,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    removeCoupon() {
        return this.delete('/api/red/coupon').then(this.handleResponse).catch(this.handleErrorResponse);
    }
    setRegisterNewsletter(register_newsletter) {
        return this.patch('/api/red/registerNewsletter', {
            register_newsletter,
        })
            .then(this.handleResponse)
            .catch(this.handleErrorResponse);
    }
    getValidPickupDates() {
        return this.get('/api/red/validPickupDates').then(function (response) {
            return response.data.map((stringedDate) => {
                return new Date(stringedDate);
            });
        });
    }
    getCartBag() {
        return this.get('/api/red/cartBag').then(this.handleResponse).catch(this.handleErrorResponse);
    }
}

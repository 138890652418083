import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
    name: 'slide-header-mobile',
    props: {
        header_slider_texts: {
            type: Array,
            required: true,
        },
        id: {
            required: true,
            type: String,
        },
    },
    computed: Object.assign({}, mapGetters('blue', {
        cart_totals: 'cartTotals',
    })),
});

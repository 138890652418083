import { defineComponent } from 'vue';
import eventBus from '@/services/EventBus';
import popupManager from '@/services/Dom/PopupManager';
import { CouponType } from '@/models/account/Coupon';
import CouponList from '@/components/Base/Coupons/CouponList.vue';
import { mapActions, mapGetters } from 'vuex';
export default defineComponent({
    name: 'coupon-popup',
    data() {
        return {
            couponType: null,
        };
    },
    components: {
        CouponList,
    },
    props: {
        showMobileStyle: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        eventBus.on('openCouponPopup', this.onOpenEvent);
    },
    computed: Object.assign(Object.assign(Object.assign({}, mapGetters('blue', { currentBlueCouponCode: 'currentCouponCode' })), mapGetters('red', { currentRedCouponCode: 'currentCouponCode' })), { currentSelectedCouponCode() {
            switch (this.couponType) {
                case CouponType.BLUE:
                    return this.currentBlueCouponCode;
                case CouponType.RED:
                    return this.currentRedCouponCode;
                default:
                    return '';
            }
        } }),
    methods: Object.assign(Object.assign(Object.assign({}, mapActions('blue', {
        storeBlueCouponCode: 'SET_COUPON_CODE',
        removeBlueCouponCode: 'REMOVE_COUPON_CODE',
    })), mapActions('red', {
        storeRedCouponCode: 'SET_COUPON_CODE',
        removeRedCouponCode: 'REMOVE_COUPON_CODE',
    })), { onOpenEvent(event) {
            this.couponType = event.type;
            popupManager.showPopup('voucher');
        },
        applyCoupon(coupon) {
            if (coupon.type === CouponType.BLUE) {
                if (this.currentBlueCouponCode !== coupon.code) {
                    this.storeBlueCouponCode(coupon.code);
                }
                else {
                    this.removeBlueCouponCode();
                }
            }
            else if (coupon.type === CouponType.RED) {
                if (this.currentRedCouponCode !== coupon.code) {
                    this.storeRedCouponCode(coupon.code);
                }
                else {
                    this.removeRedCouponCode();
                }
            }
        } }),
});

import { redState } from './state';
import { redGetters } from './getters';
import { redMutations } from './mutations';
import { redActions } from './actions';
export const redModule = {
    actions: redActions,
    getters: redGetters,
    mutations: redMutations,
    state: redState,
    namespaced: true,
};

import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
    name: 'logos-known-by',
    data() {
        return {
            logos: [
                {
                    path: '/images/_new_brand/logos_bekannt_aus/welt_logo.svg',
                    link: 'https://www.welt.de/print/welt_kompakt/webwelt/article160056886/MySwooop-ist-Ebay-fuer-Faule.html',
                    alt: 'Welt',
                    title: 'Zum mySWOOOP Artikel auf Welt',
                    extraStyling: 'height: 20px;',
                },
                {
                    path: '/images/_new_brand/logos_bekannt_aus/utopia_logo.svg',
                    link: 'https://utopia.de/ratgeber/gebrauchte-handys-verkaufen/',
                    alt: 'Utopia',
                    title: 'Zum mySWOOOP Artikel auf Utopia',
                    extraStyling: 'height: 40px;',
                },
                {
                    path: '/images/_new_brand/logos_bekannt_aus/wirtschaftswoche_logo.svg',
                    link: 'https://www.wiwo.de/unternehmen/dienstleister/umfrage-zu-kundenfavoriten-kundenfavoriten-alle-unternehmen-alle-branchen/29160934-2.html',
                    alt: 'Wirtschaftswoche',
                    title: 'Zum mySWOOOP Artikel in der Wirtschaftswoche',
                    extraStyling: 'height: 40px; max-width: 160px;',
                },
                {
                    path: '/images/_new_brand/logos_bekannt_aus/ntv_logo.svg',
                    link: 'https://www.n-tv.de/ratgeber/Deutschlands-beste-Online-Portale-2023-article24112623.html',
                    alt: 'nTV',
                    title: 'Zum mySWOOOP Artikel auf n-TV',
                    extraStyling: 'height: 40px; max-width: 160px;',
                },
                {
                    path: '/images/_new_brand/logos_bekannt_aus/sz_logo.svg',
                    link: 'https://www.sueddeutsche.de/wissen/technik-das-alte-smartphone-im-netz-zu-geld-machen-dpa.urn-newsml-dpa-com-20090101-200325-99-468242',
                    alt: 'Süddeutsche',
                    title: 'Zum mySWOOOP Artikel in der Süddeutschen',
                    extraStyling: 'height: 40px; max-width: 160px;',
                },
                {
                    path: '/images/_new_brand/logos_bekannt_aus/forbes_logo.svg',
                    link: 'https://www.forbes.com/advisor/de/telekommunikation/handy/handy-verkaufen/',
                    alt: 'Forbes',
                    title: 'Zum mySWOOOP Artikel auf Forbes',
                    extraStyling: 'height: 20px; max-width: 160px;',
                },
                {
                    path: '/images/_new_brand/logos_bekannt_aus/computerbild_logo.svg',
                    link: 'https://www.computerbild.de/artikel/cb-News-Internet-mySwooop-Gebrauchte-Elektronik-zu-Geld-machen-35782661.html',
                    alt: 'ComputerBILD',
                    title: 'Zum mySWOOOP Artikel in der ComputerBILD',
                    extraStyling: 'height: 40px; max-width: 160px;',
                },
            ],
        };
    },
    computed: Object.assign({}, mapGetters('global', {
        isMobile: 'isMobile',
    })),
});

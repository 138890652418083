import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import productBox from '@/components/Blue/ProductBox.vue';
export default defineComponent({
    name: 'emarsys-recos',
    props: {
        displaySize: {
            required: false,
            type: Number,
            default: 4,
        },
        headline: {
            type: String,
            required: false,
        },
        subHeadline: {
            type: String,
            required: false,
        },
        noButtons: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            currentStartIndex: 0,
        };
    },
    components: {
        productBox,
    },
    computed: Object.assign(Object.assign({}, mapGetters('blue', {
        recos: 'getEmarsysRecos',
    })), { currentRecos() {
            return this.recos.slice(this.currentStartIndex, this.currentStartIndex + this.displaySize);
        },
        leftButtonDisabled() {
            return !this.recos || this.currentStartIndex < this.displaySize;
        },
        rightButtonDisabled() {
            return !this.recos || this.recos.length === this.currentStartIndex + this.displaySize;
        } }),
    methods: {
        loadPrevious() {
            if (this.currentStartIndex < this.displaySize) {
                return;
            }
            this.currentStartIndex -= this.displaySize;
        },
        loadNext() {
            if (this.recos.length === this.currentStartIndex + this.displaySize) {
                return;
            }
            this.currentStartIndex += this.displaySize;
        },
    },
});

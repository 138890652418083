import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
    name: 'iso-certification',
    data() {
        return {
            slides: [
                {
                    imgPath: '/images/_new_brand/logo/zertifikate/zertifikat_ISO9001.png',
                    alt: 'ISO 14001',
                    text: 'ISO 9001<br>Qualitätsmanagement',
                },
                {
                    imgPath: '/images/_new_brand/logo/zertifikate/zertifikat_ISO14001.png',
                    alt: 'Wirtschaftswoche',
                    text: 'ISO 14001<br>Umweltmanagement',
                },
                {
                    imgPath: '/images/_new_brand/logo/zertifikate/zertifikat_ISO45001.png',
                    alt: 'Wirtschaftswoche',
                    text: 'ISO 45001<br>Arbeitsschutzmanagement',
                },
            ],
        };
    },
    computed: Object.assign({}, mapGetters('global', {
        isMobile: 'isMobile',
    })),
});

import { defineComponent } from 'vue';
import { CouponValueType } from '@/models/account/Coupon';
import { mapGetters } from 'vuex';
import FormattedDate from '@/components/Base/Formats/FormattedDate.vue';
export default defineComponent({
    name: 'coupon-card',
    components: { FormattedDate },
    emits: ['applyCoupon'],
    props: {
        coupon: {
            type: Object,
            required: true,
        },
        currentSelectedCouponCode: {
            type: String,
            default: '',
        },
    },
    computed: Object.assign(Object.assign({}, mapGetters('blue', {
        currentBlueCouponCode: 'currentCouponCode',
    })), { isShipping() {
            return this.coupon.value_type === CouponValueType.FREE_SHIPPING;
        },
        isFixed() {
            return this.coupon.value_type === CouponValueType.FIXED;
        },
        isPercent() {
            return this.coupon.value_type === CouponValueType.PERCENT;
        },
        formatedValue() {
            return this.coupon.value === null ? '' : String(Math.round(this.coupon.value));
        },
        isCurrentCode() {
            return this.coupon.code === this.currentSelectedCouponCode;
        } }),
    methods: {
        applyCode() {
            this.$emit('applyCoupon', this.coupon);
        },
    },
});

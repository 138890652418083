import { defineComponent } from 'vue';
import FaqEntry from '@/components/Base/FAQ/FaqEntry.vue';
import index from 'vuex';
export default defineComponent({
    name: 'faq-display',
    components: { FaqEntry },
    data() {
        return {
            calculatedOffsets: 0,
        };
    },
    props: {
        faq_list: {
            type: Array,
            required: true,
        },
        stickyDomPartSelectors: {
            type: Array,
            default: () => {
                return ['.JS_fixedHeaderPart'];
            },
        },
        json_ld_only: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    methods: {
        calcStickyOffsets() {
            var _a;
            let offset = 0;
            (_a = this.stickyDomPartSelectors) === null || _a === void 0 ? void 0 : _a.forEach((selector) => {
                var _a;
                const element = document.querySelector(selector);
                const currentHeight = (_a = element === null || element === void 0 ? void 0 : element.getBoundingClientRect().height) !== null && _a !== void 0 ? _a : 0;
                offset += currentHeight;
            });
            return offset;
        },
    },
    computed: {
        index() {
            return index;
        },
        jsonld() {
            const entries = [];
            this.faq_list.forEach(function (list) {
                list.items.forEach(function (entry) {
                    entries.push({
                        '@type': 'Question',
                        name: entry.question,
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: entry.answer,
                        },
                    });
                });
            });
            const jsonld = {
                '@context': 'http://schema.org/',
                '@type': 'FAQPage',
                mainEntity: entries,
            };
            return JSON.stringify(jsonld);
        },
    },
    mounted() {
        this.calculatedOffsets = this.calcStickyOffsets();
    },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_faq_display = _resolveComponent("faq-display");
    return (_ctx.bluePaymentMethods.length)
        ? (_openBlock(), _createBlock(_component_faq_display, {
            key: 0,
            faq_list: [
                {
                    title: '',
                    items: _ctx.bluePaymentMethods,
                },
            ]
        }, null, 8, ["faq_list"]))
        : _createCommentVNode("", true);
}

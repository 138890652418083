function showHint(jquery_element) {

    const placeholder = jquery_element.attr('placeholder');
    if (!placeholder) {
        //nothing to render
        return;
    }
    const $hint = jquery_element.siblings('.hint');
    if ($hint.length > 0) {
        // already rendered
        return;
    }

    const hint_html = $('<div>');
    hint_html.addClass('hint').addClass('fade-in');
    hint_html.css('background-color', jquery_element.css('background-color'));
    hint_html.html(placeholder);
    jquery_element.parent().prepend(hint_html);

}

// Initial Hint anzeigen wenn Feld korrekt ausgefüllt ist:
$(() => {
    $('input.form-control.is-valid').each(function () {
        if ($(this).val()) {
            showHint($(this));
        }
    });
});

/*auto-move placeholder to top left of input*/
$(document).on('keyup', 'input.form-control', function () {

    const jquery_element = $(this);

    if (jquery_element.hasClass('js-nohint')) {
        //disabled
        return;
    }

    if (!jquery_element.val()) {

        // emptied ? move back to placeholder
        jquery_element.siblings('.hint').remove();
        return;
    }

    showHint(jquery_element);

});


/* handle validation */
$(document).on('change', 'input.form-control.js-needs-validation', function (e) {

    const $input = $(this);

    window.validateAsync($input);


});

window.validateAsync = (jqueryObject) => {
    if (!jqueryObject.val()) {
        if (render_invalid_empty(jqueryObject)) {
            return;
        }
        render_invalid(jqueryObject);
        return;
    }

    const type = jqueryObject.attr('data-validation-type') || jqueryObject.attr('type');

    window.axios.post('/validate', {type: type, payload: jqueryObject.val()})
          .then(response => {
              if (response.data.result === 1) {
                  render_valid(jqueryObject);
                  return;
              }
              render_invalid(jqueryObject);
          })
          .catch(() => {
                  render_invalid(jqueryObject);
              },
          );
};

window.render_valid = ($jquery_obj, message) => {
    if (!check_form_control($jquery_obj)) {
        return;
    }


    hideInvalidEmptyMessage($jquery_obj);
    $jquery_obj.removeClass('is-invalid');
    $jquery_obj.addClass('is-valid');
    $jquery_obj.addClass('was-validated');
    if (message) {
        $jquery_obj.siblings('div.valid-feedback').text(message);
    }

    $jquery_obj.trigger('validated');

};

window.render_invalid_empty = ($jquery_obj, message) => {

    if (!check_form_control($jquery_obj)) {
        return false;
    }

    $jquery_obj.addClass('is-invalid');
    $jquery_obj.removeClass('is-valid');
    $jquery_obj.removeClass('was-validated');
    $jquery_obj.siblings('.invalid-feedback').addClass('d-none');

    const $empty_fail = $jquery_obj.siblings('.invalid-feedback-empty');
    if ($empty_fail.length === 0) {
        return false;
    }

    if (message) {
        $empty_fail.text(message);
    }
    $empty_fail.removeClass('hidden');
    $jquery_obj.trigger('validated');
    return true;
};

function hideInvalidEmptyMessage($jquery_obj) {
    $jquery_obj.siblings('.invalid-feedback-empty').addClass('hidden');
    $jquery_obj.siblings('.invalid-feedback').removeClass('d-none');
}


window.render_invalid = ($jquery_obj, message) => {
    if (!check_form_control($jquery_obj)) {
        return;
    }


    hideInvalidEmptyMessage($jquery_obj);
    $jquery_obj.addClass('is-invalid');
    $jquery_obj.removeClass('is-valid');
    $jquery_obj.addClass('was-validated');
    if (message) {
        $jquery_obj.siblings('div.invalid-feedback').text(message);
    }
    $jquery_obj.trigger('validated');
};

function check_form_control($jquery_obj) {
    let valid = true;
    $.each($jquery_obj, (i, e) => {
        valid = $(e).hasClass('form-control') & valid;
    });

    return valid;
}

window.validateSection = selector => {
    let valid = true;
    $(selector).find('input').each((i, e) => {
        valid = valid && $(e).hasClass('is-valid');
    });
    return valid;

};

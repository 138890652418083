import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1a0b1c5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "position-relative"
};
const _hoisted_2 = {
    key: 0,
    class: "invalid-feedback"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_text_field = _resolveComponent("v-text-field");
    return (_ctx.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "inputPrepend", {
                meta: _ctx.meta,
                disabled: _ctx.disabled,
                readonly: _ctx.readonly,
                placeholder: _ctx.placeholder,
                value: _ctx.inputValue
            }, undefined, true),
            _createVNode(_component_v_text_field, {
                label: _ctx.label !== '' ? _ctx.label : _ctx.placeholder,
                class: _normalizeClass({
                    'is-invalid': !_ctx.meta.valid,
                    'is-valid': _ctx.meta.valid,
                    'was-validated': _ctx.meta.validated,
                }),
                name: _ctx.name,
                type: _ctx.fieldType,
                placeholder: _ctx.placeholder,
                autocomplete: _ctx.autocomplete,
                modelValue: _ctx.inputValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.inputValue) = $event)),
                onInput: _ctx.handleChange,
                onBlur: _ctx.handleBlur,
                disabled: _ctx.disabled,
                readonly: _ctx.readonly
            }, null, 8, ["label", "class", "name", "type", "placeholder", "autocomplete", "modelValue", "onInput", "onBlur", "disabled", "readonly"]),
            _renderSlot(_ctx.$slots, "inputAppend", {
                meta: _ctx.meta,
                disabled: _ctx.disabled,
                readonly: _ctx.readonly,
                placeholder: _ctx.placeholder,
                value: _ctx.inputValue
            }, undefined, true),
            (_ctx.errorMessage && _ctx.meta.validated)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
                : _createCommentVNode("", true)
        ]))
        : _createCommentVNode("", true);
}

import { h } from 'vue';
import { VClassIcon } from 'vuetify/lib/composables/icons.mjs'; // Utilities
export const aliases = {
    collapse: 'icon-brand_arrow_down',
    complete: 'icon-checked',
    cancel: 'icon-cancel',
    close: 'icon-brand_close',
    delete: 'icon-cancel-circled',
    clear: 'icon-cancel-circled',
    success: 'icon-haken-check',
    info: 'icon-brand_ausrufezeichen',
    warning: 'icon-attention',
    error: 'icon-cancel-circled',
    prev: 'icon-angle-left',
    next: 'icon-angle-right',
    checkboxOn: ' icon-check-box_checked_new',
    checkboxOff: 'icon-check-box_new',
    checkboxIndeterminate: 'icon-toggle-off',
    delimiter: 'icon-brand_minus',
    sort: 'icon-sort-alt-up',
    expand: 'icon-brand_arrow_down',
    menu: 'icon-menu',
    subgroup: 'icon-brand_arrow_down',
    dropdown: 'icon-brand_arrow_down',
    radioOn: 'icon-dot-circled',
    radioOff: 'icon-circle-empty',
    edit: 'icon-edit',
    ratingEmpty: 'icon-heart-empty',
    ratingFull: 'icon-heart',
    ratingHalf: 'icon-heart-empty',
    loading: 'icon-spin6',
    first: 'icon-angle-double-left',
    last: 'icon-angle-double-right',
    unfold: 'icon-brand_arrow_down',
    file: 'icon-folder-empty',
    plus: 'icon-brand_plus',
    minus: 'icon-brand_minus',
    sortAsc: 'icon-sort-alt-up',
    sortDesc: 'icon-sort-alt-down',
    calendar: 'icon-calendar',
    // Custom
    logo: 'icon-brand_swooop',
    qrcode: 'icon-qrcode',
    barcode: 'icon-barcode',
    bell: 'icon-bell',
    fire: 'icon-002-fire',
    search: 'icon-brand_search',
    rocket: 'icon-rocket',
    user: 'icon-brand_user',
    coffee: 'icon-icon-kaffee',
};
export const myswooopIconSet = {
    component: (props) => h(VClassIcon, Object.assign(Object.assign({}, props), { class: '' })),
};

import { userApiClient } from '@/services/api';
import eventBus from '@/services/EventBus';
export const accountActions = {
    SET_ITEM_TO_WISHLIST(store, id) {
        return userApiClient.addProductToWishlist(id).then((ids) => {
            store.commit('SET_WISHLIST_ITEMS', ids);
            return true;
        });
    },
    REMOVE_ITEM_FROM_WISHLIST(store, id) {
        return userApiClient.removeProductFromWishlist(id).then((ids) => {
            store.commit('SET_WISHLIST_ITEMS', ids);
            return true;
        });
    },
    LOAD_WISHLIST(store) {
        return userApiClient.getWishlistProductIds().then((ids) => {
            store.commit('SET_WISHLIST_ITEMS', ids);
            return true;
        });
    },
    LOGIN() {
        return new Promise(function (resolve) {
            resolve(false);
        });
    },
    LOGOUT() {
        return new Promise(function (resolve) {
            resolve(false);
        });
    },
    PULL_USER(store) {
        return userApiClient
            .pullUser()
            .then(function (user) {
            store.commit('SET_CURRENT_USER', user);
            eventBus.emit('user_set_to_store');
            return true;
        })
            .catch((response) => {
            if (response.status === 401) {
                store.commit('RESET_CURRENT_USER', undefined);
            }
            return false;
        });
    },
    NEWSLETTER_TOGGLE(store, enabled) {
        return userApiClient.toggleNewsletter(enabled).then(function (user) {
            store.commit('SET_CURRENT_USER', user);
            return true;
        });
    },
    REGISTER(store, registerData) {
        return userApiClient.registerUser(registerData).then(function (user) {
            store.commit('SET_CURRENT_USER', user);
            return true;
        });
    },
};

import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import ProductBoxSmall from '@/components/Blue/Cart/ProductBoxSmall.vue';
import FormattedPrice from '@/components/Base/Formats/FormattedPrice.vue';
export default defineComponent({
    name: 'CartDisplaySmall',
    components: {
        FormattedPrice,
        ProductBoxSmall,
    },
    computed: Object.assign(Object.assign({}, mapGetters('blue', {
        current_products: 'currentProducts',
        product_total: 'brutto_products',
    })), { total() {
            return this.product_total;
        } }),
});

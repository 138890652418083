var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CouponType } from '@/models/account/Coupon';
import { blueCartApiClient, blueProductsApiClient, couponApiClient, filterApiClient, userApiClient, } from '@/services/api';
import { AddressTypes } from '@/models/account/Address';
import httpClient from '@/services/api/axios';
import sleep from '@/helpers/sleep';
import { datalayerService, facebookTrackingService } from '@/services/tracking';
import eventBus from '@/services/EventBus';
function handleCartApiRequest(request, store) {
    store.commit('RESET_CART_ERRORS', undefined);
    return request
        .then(function (response) {
        store.commit('SET_CART', response.cart);
        store.commit('SET_VALID_CART', response.valid_for_checkout);
        store.commit('SET_ADDITIONAL_PAYMENT_INFO', response.additional_payment_info);
        store.commit('SET_PAYMENT_TYPES', response.payment_types);
        store.commit('SET_SHIPPING_OPTIONS', response.shipping_options);
        return;
    })
        .catch(function (error) {
        var _a;
        if (error.cart) {
            store.commit('SET_CART', error.cart);
        }
        store.commit('SET_CART_ERRORS', error.errors);
        store.commit('SET_VALID_CART', (_a = error.valid_for_checkout) !== null && _a !== void 0 ? _a : false);
        if (error.additional_payment_info) {
            store.commit('SET_ADDITIONAL_PAYMENT_INFO', error.additional_payment_info);
        }
        if (error.payment_types) {
            store.commit('SET_PAYMENT_TYPES', error.payment_types);
        }
        if (error.shipping_options) {
            store.commit('SET_SHIPPING_OPTIONS', error.shipping_options);
        }
        throw error.errors;
    });
}
export const blueActions = {
    SET_COUPON_CODE(store, code) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient.addCoupon(code), store);
    },
    REMOVE_COUPON_CODE(store) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient.removeCoupon(), store);
    },
    ADD_PRODUCT(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient.addProduct(payload.product_id, payload.quantity), store);
    },
    REMOVE_PRODUCT(store, product_id) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient.removeProduct(product_id), store);
    },
    SET_QUANTITY(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient.changeProductQuantity(payload.product_id, payload.quantity), store);
    },
    SET_SHIPPING_OPTION(store, shipping_option_id) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient.setShippingOption(shipping_option_id), store);
    },
    SET_PAYMENT_TYPE(store, payment_type_id) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient.setPaymentType(payment_type_id), store);
    },
    SET_SHIPPING_ADDRESS(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient
            .setAddress(AddressTypes.shipping, payload.address, payload.storeAsDefault)
            .then(function (cartResponse) {
            if (!payload.shippingAddressIsBillingAddress || !cartResponse.cart) {
                return cartResponse;
            }
            return blueCartApiClient.setAddress(AddressTypes.billing, cartResponse.cart.addresses.shipping, payload.storeAsDefault);
        }), store);
    },
    SET_BILLING_ADDRESS(store, payload) {
        store.commit('SET_VALID_CART', null);
        return handleCartApiRequest(blueCartApiClient.setAddress(AddressTypes.billing, payload.address, payload.storeAsDefault), store);
    },
    LOAD_COUPONS(store) {
        return couponApiClient.getCoupons(CouponType.BLUE).then(function (coupons) {
            store.commit('SET_COUPONS', coupons);
        });
    },
    PULL_CART(store) {
        return handleCartApiRequest(blueCartApiClient.getCart(false), store)
            .then(() => true)
            .catch(() => false);
    },
    START_CHECKOUT(store) {
        store.commit('SET_CHECKOUT_IS_IN_PROGRESS', true);
        store.commit('SET_VALID_CART', null);
        return blueCartApiClient
            .getCart(true)
            .catch(() => {
            return Promise.reject('Leider konnten wir den Server nicht erreichen!');
        })
            .then((cart_response) => {
            if (!cart_response.valid_for_checkout || cart_response.lock !== true) {
                return handleCartApiRequest(Promise.resolve(cart_response), store).then(() => Promise.reject('Der Warenkorb konnte nicht gesperrt werden!'));
            }
            // check if frontend gets new data from server
            if (JSON.stringify(cart_response.cart) !== JSON.stringify(store.state.cart)) {
                return handleCartApiRequest(Promise.resolve(cart_response), store).then(() => Promise.reject('Der Warenkorb konnte nicht gesperrt werden!'));
            }
            return handleCartApiRequest(Promise.resolve(cart_response), store).then(() => Promise.resolve(true));
        });
    },
    STOP_CHECKOUT(store) {
        store.commit('SET_CHECKOUT_IS_IN_PROGRESS', false);
        return Promise.resolve();
    },
    TOGGLE_BILLING_ADDRESS_USAGE(store, use) {
        return handleCartApiRequest(blueCartApiClient.toggleBillingAddressUsage(use), store);
    },
    SET_EMAIL(store, email) {
        var _a;
        return handleCartApiRequest(blueCartApiClient.setEmail(email, (_a = store.state.guest_registration_data.shouldRegister) !== null && _a !== void 0 ? _a : false), store);
    },
    SET_REGISTER_NEWSLETTER(store, register) {
        return handleCartApiRequest(blueCartApiClient.setRegisterNewsletter(register), store);
    },
    SET_SHOULD_REGISTER(store, shouldRegister) {
        var _a, _b;
        store.commit('SET_SHOULD_REGISTER', shouldRegister);
        if (!shouldRegister) {
            store.commit('SET_REGISTRATION_PASSWORD', '');
        }
        return store.dispatch('SET_EMAIL', (_b = (_a = store.state.cart) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : '');
    },
    SET_REGISTRATION_PASSWORD(store, password) {
        store.commit('SET_REGISTRATION_PASSWORD', password);
        if (password !== '') {
            store.commit('SET_SHOULD_REGISTER', true);
        }
        return Promise.resolve();
    },
    REGISTER_FROM_ORDER(store, payload) {
        return userApiClient
            .registerUserFromBlueOrder(payload.orderId, payload.password, payload.confirmedPassword)
            .then((user) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            store.commit('account/SET_CURRENT_USER', user);
            return true;
        });
    },
    LOAD_BLUE_PAYMENTS(store) {
        return httpClient
            .get('/api/blue/payments ')
            .then((response) => {
            store.commit('SET_BLUE_PAYMENTS', response.data);
            return true;
        })
            .catch((error) => {
            console.error(error);
            return false;
        });
    },
    LOAD_CURRENT_PRODUCT(store, productId) {
        return httpClient
            .get(`api/blue/product/${productId}`)
            .then((response) => {
            store.commit('SET_CURRENT_PRODUCT', response.data);
            datalayerService.pushProductView(response.data);
            datalayerService.pushCategoryPath(response.data.categoryPath);
            datalayerService.pushCategoryPathBlue(response.data.categoryPathBlue);
            facebookTrackingService.trackProductShow(response.data);
            return true;
        })
            .catch(() => {
            window.location.replace('/kaufen/info/notFound');
            return false;
        });
    },
    LOAD_BLUE_FILTERS(store) {
        if (store.state.listingType === null) {
            return Promise.reject();
        }
        return filterApiClient
            .getAvailableFilters(store.state.listingType, store.state.listingTypeValue)
            .then((data) => {
            store.commit('SET_AVAILABLE_FILTERS', data);
            return true;
        })
            .catch((error) => {
            console.log(error);
            return false;
        });
    },
    LOAD_PRICE_BARS(store) {
        if (store.state.listingType === null) {
            return Promise.reject();
        }
        return filterApiClient
            .getPriceBars(store.state.listingType, store.state.listingTypeValue)
            .then((data) => {
            store.commit('SET_PRICE_BARS', data);
            return true;
        })
            .catch((error) => {
            console.log(error);
            return false;
        });
    },
    LOAD_PRODUCTS_LISTING(store) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            if (store.state.listingType === null) {
                return Promise.reject();
            }
            if (store.state.appliedFilters === null) {
                return Promise.reject();
            }
            const thisRequest = store.state.currentBlueListingRequest;
            store.commit('INCREASE_LISTING_REQUEST_COUNTER', 1);
            // prevent ajax overload
            while (store.getters['global/hasPendingAjaxRequest']) {
                yield sleep(20);
                if (thisRequest !== store.state.currentBlueListingRequest) {
                    return Promise.reject();
                }
            }
            return blueProductsApiClient
                .getProducts((_b = (_a = store.state.currentPageInfo) === null || _a === void 0 ? void 0 : _a.current_page) !== null && _b !== void 0 ? _b : 1, (_d = (_c = store.state.currentPageInfo) === null || _c === void 0 ? void 0 : _c.per_page) !== null && _d !== void 0 ? _d : 12, store.state.listingType, store.state.listingTypeValue, store.state.currentSorting, store.state.appliedFilters.priceRange, store.state.appliedFilters.conditions.map((filter) => filter.numericValue), store.state.appliedFilters.brands.map((filter) => filter.numericValue), store.state.appliedFilters.isDeal)
                .then((data) => {
                store.commit('SET_CURRENT_PRODUCTS', data.products);
                store.commit('PATCH_PAGEINFO', data.pageInfo);
                datalayerService.pushProductListing(data.products);
                if (data.products.length) {
                    datalayerService.pushCategoryPath(data.products[0].categoryPath, data.category_depth);
                    datalayerService.pushCategoryPathBlue(data.products[0].categoryPathBlue, data.category_depth);
                }
                return true;
            })
                .catch((error) => {
                console.log(error);
                return false;
            });
        });
    },
    UPDATE_FILTERS(store, filters) {
        store.commit('SET_FILTERS', filters);
        if (!store.state.currentPageInfo) {
            return Promise.resolve();
        }
        store.commit('SET_CURRENT_PAGE', 1);
        return store.dispatch('LOAD_PRODUCTS_LISTING');
    },
    INIT_LISTING(store, payload) {
        store.commit('SET_LISTING', payload);
        return Promise.all([
            store.dispatch('LOAD_BLUE_FILTERS'),
            store.dispatch('LOAD_PRICE_BARS'),
            store.dispatch('LOAD_PRODUCTS_LISTING'),
        ]);
    },
    PRODUCT_LISTING_SET_PRICE_SORTING(store, sorting) {
        store.commit('SET_CURRENT_SORTING', sorting);
        if (!store.state.currentPageInfo) {
            return Promise.resolve();
        }
        store.commit('SET_CURRENT_PAGE', 1);
        return store.dispatch('LOAD_PRODUCTS_LISTING');
    },
    RESET_FILTERS(store) {
        var _a, _b, _c, _d;
        store.commit('SET_FILTERS', {
            conditions: [],
            brands: [],
            priceRange: null,
            isDeal: false,
        });
        if (!store.state.currentPageInfo) {
            return Promise.resolve();
        }
        store.commit('SET_CURRENT_PAGE', 1);
        eventBus.emit('priceRange:loaded', {
            min: (_b = (_a = [...store.state.priceBars].shift()) === null || _a === void 0 ? void 0 : _a.minPrice) !== null && _b !== void 0 ? _b : 0,
            max: (_d = (_c = [...store.state.priceBars].pop()) === null || _c === void 0 ? void 0 : _c.maxPrice) !== null && _d !== void 0 ? _d : 0,
        });
        return store.dispatch('LOAD_PRODUCTS_LISTING');
    },
    SET_DEALS_ONLY(store, dealsOnly) {
        store.commit('SET_DEALS', dealsOnly);
        if (!store.state.currentPageInfo) {
            return Promise.resolve();
        }
        store.commit('SET_CURRENT_PAGE', 1);
        return store.dispatch('LOAD_PRODUCTS_LISTING');
    },
    SET_PRICE_RANGE(store, range) {
        store.commit('SET_PRICE_RANGE', range);
        if (!store.state.currentPageInfo) {
            return Promise.resolve();
        }
        store.commit('SET_CURRENT_PAGE', 1);
        return store.dispatch('LOAD_PRODUCTS_LISTING');
    },
};

import { defineComponent } from 'vue';
import optimizeImage from '@/services/ImageOptimize';
import { mapGetters, mapActions } from 'vuex';
import euroFormatter from '@/services/formattter/Currency';
import eventBus from '@/services/EventBus';
export default defineComponent({
    name: 'product-box',
    props: {
        product: {
            type: Object,
            required: true,
        },
        isModelBoxView: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: Object.assign(Object.assign({}, mapActions('account', {
        setItemToWishlist: 'SET_ITEM_TO_WISHLIST',
        removeItemFromWishlist: 'REMOVE_ITEM_FROM_WISHLIST',
    })), { optimizeImage,
        wishListAction(id) {
            if (this.isLogin == false) {
                eventBus.emit('openInfoPopup', {
                    icon: 'warning',
                    headline: 'Wunschliste',
                    message: 'Um Deine Wunschliste zu pflegen, musst Du Dich einloggen.',
                });
                return;
            }
            if (this.isOnWishlist(id)) {
                this.removeItemFromWishlist(id);
                return;
            }
            this.setItemToWishlist(id);
        },
        isOnWishlist(id) {
            return this.wishlist.includes(id);
        } }),
    computed: Object.assign(Object.assign(Object.assign({}, mapGetters('global', {
        isMobile: 'isMobile',
    })), mapGetters('account', {
        isLogin: 'isLogin',
        wishlist: 'wishlist',
    })), { originalPriceCommaSeperated() {
            return euroFormatter.format(this.product.originalPrice);
        },
        dealPriceCommaSeparated() {
            if (!this.product.dealPrice) {
                return '';
            }
            return euroFormatter.format(this.product.dealPrice);
        } }),
});

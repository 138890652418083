import { defineComponent } from 'vue';
import { CouponType } from '@/models/account/Coupon';
import CouponCard from '@/components/Base/Coupons/CouponCard.vue';
import { mapActions, mapState } from 'vuex';
export default defineComponent({
    name: 'coupon-list',
    components: {
        CouponCard,
    },
    emits: ['applyCoupon'],
    props: {
        couponType: {
            type: String,
            required: true,
        },
        loadOnMount: {
            type: Boolean,
            default: false,
        },
        currentSelectedCouponCode: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    mounted() {
        this.loadCoupons();
    },
    computed: Object.assign(Object.assign(Object.assign({}, mapState('blue', { blueCoupons: 'coupons' })), mapState('red', { redCoupons: 'coupons' })), { isLoading() {
            return this.coupons === null;
        },
        coupons() {
            switch (this.couponType) {
                case CouponType.BLUE:
                    return this.blueCoupons;
                case CouponType.RED:
                    return this.redCoupons;
                default:
                    return null;
            }
        } }),
    methods: Object.assign(Object.assign(Object.assign({}, mapActions('blue', {
        loadBlueCoupons: 'LOAD_COUPONS',
    })), mapActions('red', {
        loadRedCoupons: 'LOAD_COUPONS',
    })), { applyCoupon(coupon) {
            this.$emit('applyCoupon', coupon);
        },
        loadCoupons() {
            switch (this.couponType) {
                case CouponType.BLUE:
                    this.loadBlueCoupons();
                    break;
                case CouponType.RED:
                    this.loadRedCoupons();
                    break;
            }
        } }),
});
